import withRouter from "@/components/functional-hook-wrapper";
import React from "react";
import styles from './cafe.module.css';

export type HomeCafeProps = {
    title_top?: string;
    body_top?: string;
    cta_text_top?: string;
    cta_text_link_top?: string;

    title_bot?: string;
    body_bot?: string;
    cta_text_bot?: string;
    cta_text_link_bot?: string;
}

export class HomeCafe extends React.Component<HomeCafeProps> {
    router: any;

    constructor(props: any) {
        super(props);

        this.router = props.router;
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.container_inner}>
                    
                    <div className={styles.content_container}>
                        <div className={styles.image_container}>
                            <img src={`${process.env.PUBLIC_URL}/assets/home/home_cafe-hero2.png`} className={styles.image} />
                        </div>

                        <div style={{flex: 1}}>
                            <div className={styles.top_text_container}>
                                <div className={styles.title}>{this.props.title_top}</div>
                                <div className={styles.body}>{this.props.body_top}</div>
                                <div onClick={() => {
                                    this.router.navigate(this.props.cta_text_link_top);
                                }} className={styles.button}>{this.props.cta_text_top}</div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.content_container} style={{marginTop: 60}}>
                        <div style={{flex: 1}}>
                            <div className={styles.bottom_text_container}>
                                <div className={styles.title}>{this.props.title_bot}</div>
                                <div className={styles.body}>{this.props.body_bot}</div>
                                <div onClick={() => {
                                    this.router.navigate(this.props.cta_text_link_bot);
                                }} className={styles.button}>{this.props.cta_text_bot}</div>
                            </div>
                        </div>

                        <div className={styles.image_container}>
                            <img src={`${process.env.PUBLIC_URL}/assets/home/home_cafe-hero1.png`} className={styles.image} />
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(HomeCafe);