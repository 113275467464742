import AppPromoBanner from "@/components/app-promo-banner";
import { registerUser, setProfile } from "@/services/users";
import React from "react";

import styles from './index.module.css';

export default class AccountRegister extends React.Component {
    state = {
        address_1: '',
        address_2: '',
        city: '',
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        phone: '',
        state: '',
        zip: '',
        receiveNews: false,
        receiveOffers: false,
        didRegister: false
    }

    private async signUp() {
        localStorage.removeItem('token');
        await registerUser(this.state.email, this.state.password);
        await setProfile(this.state.first_name, this.state.last_name, this.state.address_1, this.state.address_2, this.state.city, this.state.state, this.state.zip, this.state.phone);
        window.location.href = '/account';

        // this.setState({ didRegister: true })
    }

    render() {
        return (
            <div className={styles.container}>
                { this.state.didRegister ?
                    <>
                        <div className={styles.title}>
                            Confirm your email address
                        </div>
                        <div className={styles.subtitle}>
                            An email was sent to the address you provided, click on the link in the email to verify your account.
                        </div>
                    </>
                    :
                    <>
                        <div>
                            <div className={styles.title}>Sign up for a Kwik Fill account</div>
                            <div className={styles.subtitle}>Thank you for signing in. Using the links on this page, you can enter our giveaways see your entries, and view the winners. You can also update your user information and register to become a Kwik Rewards Club VIP.</div>
                        </div>

                        <div>
                            <div className={styles.form_container}>
                                <div>
                                    <div className={styles.input_label}>Email Address</div>
                                    <input className={styles.input} placeholder="Email Address" value={this.state.email} onChange={(event) => {
                                        this.setState({ email: event.target.value })
                                    }} />
                                </div>

                                <div>
                                    <div className={styles.input_label}>Password</div>
                                    <input type="password" className={styles.input} placeholder="Password" value={this.state.password} onChange={(event) => {
                                        this.setState({ password: event.target.value })
                                    }}/>
                                </div>
                                <div>
                                    <div className={styles.input_label}>First Name</div>
                                    <input className={styles.input} placeholder="First Name" value={this.state.first_name} onChange={(event) => {
                                        this.setState({ first_name: event.target.value })
                                    }} />
                                </div>
                                
                                <div>
                                    <div className={styles.input_label}>Last Name</div>
                                    <input className={styles.input} placeholder="Last Name" value={this.state.last_name} onChange={(event) => {
                                        this.setState({ last_name: event.target.value })
                                    }} />
                                </div>
                                
                                <div>
                                    <div className={styles.input_label}>Phone Number</div>
                                    <input className={styles.input} placeholder="Phone Number" value={this.state.phone} onChange={(event) => {
                                        this.setState({ phone: event.target.value })
                                    }} />
                                </div>

                                <div>
                                    <div className={styles.input_label}>Address Line 1</div>
                                    <input className={styles.input} placeholder="Address Line 1" value={this.state.address_1} onChange={(event) => {
                                        this.setState({ address_1: event.target.value })
                                    }} />
                                </div>
                                
                                <div>
                                    <div className={styles.input_label}>Address Line 2</div>
                                    <input className={styles.input} placeholder="Address Line 2" value={this.state.address_2} onChange={(event) => {
                                        this.setState({ address_2: event.target.value })
                                    }} />
                                </div>

                                <div>
                                    <div className={styles.input_label}>City</div>
                                    <input className={styles.input} placeholder="City" value={this.state.city} onChange={(event) => {
                                        this.setState({ city: event.target.value })
                                    }} />
                                </div>
                                
                                <div>
                                    <div className={styles.input_label}>State</div>
                                    {/* <input className={styles.input} placeholder="State" value={this.state.state} onChange={(event) => {
                                        this.setState({ state: event.target.value })
                                    }} /> */}
                                    <select className={styles.select} onChange={
                                        (event) => {
                                            this.setState({state: event.target.value})
                                        }
                                    }>
                                        {/* Make a select option for each state in the United States, where the value is the two letter abbreviation but the display is the full state name */}
                                        <option value="AL" selected={this.state.state === "AL" || this.state.state === "Alabama"}>Alabama</option>
                                        <option value="AK" selected={this.state.state === "AK" || this.state.state === "Alaska"}>Alaska</option>
                                        <option value="AZ" selected={this.state.state === "AZ" || this.state.state === "Arizona"}>Arizona</option>
                                        <option value="AR" selected={this.state.state === "AR" || this.state.state === "Arkansas"}>Arkansas</option>
                                        <option value="CA" selected={this.state.state === "CA" || this.state.state === "California"}>California</option>
                                        <option value="CO" selected={this.state.state === "CO" || this.state.state === "Colorado"}>Colorado</option>
                                        <option value="CT" selected={this.state.state === "CT" || this.state.state === "Connecticut"}>Connecticut</option>
                                        <option value="DE" selected={this.state.state === "DE" || this.state.state === "Delaware"}>Delaware</option>
                                        <option value="DC" selected={this.state.state === "DC" || this.state.state === "District Of Columbia"}>District Of Columbia</option>
                                        <option value="FL" selected={this.state.state === "FL" || this.state.state === "Florida"}>Florida</option>
                                        <option value="GA" selected={this.state.state === "GA" || this.state.state === "Georgia"}>Georgia</option>
                                        <option value="HI" selected={this.state.state === "HI" || this.state.state === "Hawaii"}>Hawaii</option>
                                        <option value="ID" selected={this.state.state === "ID" || this.state.state === "Idaho"}>Idaho</option>
                                        <option value="IL" selected={this.state.state === "IL" || this.state.state === "Illinois"}>Illinois</option>
                                        <option value="IN" selected={this.state.state === "IN" || this.state.state === "Indiana"}>Indiana</option>
                                        <option value="IA" selected={this.state.state === "IA" || this.state.state === "Iowa"}>Iowa</option>
                                        <option value="KS" selected={this.state.state === "KS" || this.state.state === "Kansas"}>Kansas</option>
                                        <option value="KY" selected={this.state.state === "KY" || this.state.state === "Kentucky"}>Kentucky</option>
                                        <option value="LA" selected={this.state.state === "LA" || this.state.state === "Louisiana"}>Louisiana</option>
                                        <option value="ME" selected={this.state.state === "ME" || this.state.state === "Maine"}>Maine</option>
                                        <option value="MD" selected={this.state.state === "MD" || this.state.state === "Maryland"}>Maryland</option>
                                        <option value="MA" selected={this.state.state === "MA" || this.state.state === "Massachusetts"}>Massachusetts</option>
                                        <option value="MI" selected={this.state.state === "MI" || this.state.state === "Michigan"}>Michigan</option>
                                        <option value="MN" selected={this.state.state === "MN" || this.state.state === "Minnesota"}>Minnesota</option>
                                        <option value="MS" selected={this.state.state === "MS" || this.state.state === "Mississippi"}>Mississippi</option>
                                        <option value="MO" selected={this.state.state === "MO" || this.state.state === "Missouri"}>Missouri</option>
                                        <option value="MT" selected={this.state.state === "MT" || this.state.state === "Montana"}>Montana</option>
                                        <option value="NE" selected={this.state.state === "NE" || this.state.state === "Nebraska"}>Nebraska</option>
                                        <option value="NV" selected={this.state.state === "NV" || this.state.state === "Nevada"}>Nevada</option>
                                        <option value="NH" selected={this.state.state === "NH" || this.state.state === "New Hampshire"}>New Hampshire</option>
                                        <option value="NJ" selected={this.state.state === "NJ" || this.state.state === "New Jersey"}>New Jersey</option>
                                        <option value="NM" selected={this.state.state === "NM" || this.state.state === "New Mexico"}>New Mexico</option>
                                        <option value="NY" selected={this.state.state === "NY" || this.state.state === "New York"}>New York</option>
                                        <option value="NC" selected={this.state.state === "NC" || this.state.state === "North Carolina"}>North Carolina</option>
                                        <option value="ND" selected={this.state.state === "ND" || this.state.state === "North Dakota"}>North Dakota</option>
                                        <option value="OH" selected={this.state.state === "OH" || this.state.state === "Ohio"}>Ohio</option>
                                        <option value="OK" selected={this.state.state === "OK" || this.state.state === "Oklahoma"}>Oklahoma</option>
                                        <option value="OR" selected={this.state.state === "OR" || this.state.state === "Oregon"}>Oregon</option>
                                        <option value="PA" selected={this.state.state === "PA" || this.state.state === "Pennsylvania"}>Pennsylvania</option>
                                        <option value="RI" selected={this.state.state === "RI" || this.state.state === "Rhode Island"}>Rhode Island</option>
                                        <option value="SC" selected={this.state.state === "SC" || this.state.state === "South Carolina"}>South Carolina</option>
                                        <option value="SD" selected={this.state.state === "SD" || this.state.state === "South Dakota"}>South Dakota</option>
                                        <option value="TN" selected={this.state.state === "TN" || this.state.state === "Tennessee"}>Tennessee</option>
                                        <option value="TX" selected={this.state.state === "TX" || this.state.state === "Texas"}>Texas</option>
                                        <option value="UT" selected={this.state.state === "UT" || this.state.state === "Utah"}>Utah</option>
                                        <option value="VT" selected={this.state.state === "VT" || this.state.state === "Vermont"}>Vermont</option>
                                        <option value="VA" selected={this.state.state === "VA" || this.state.state === "Virginia"}>Virginia</option>
                                        <option value="WA" selected={this.state.state === "WA" || this.state.state === "Washington"}>Washington</option>
                                        <option value="WV" selected={this.state.state === "WV" || this.state.state === "West Virginia"}>West Virginia</option>
                                        <option value="WI" selected={this.state.state === "WI" || this.state.state === "Wisconsin"}>Wisconsin</option>
                                        <option value="WY" selected={this.state.state === "WY" || this.state.state === "Wyoming"}>Wyoming</option>
                                    </select>
                                </div>

                                <div>
                                    <div className={styles.input_label}>Zip</div>
                                    <input className={styles.input} placeholder="Zip Code" value={this.state.zip} onChange={(event) => {
                                        this.setState({ zip: event.target.value })
                                    }} />
                                </div>
                            </div>

                            <div style={{marginTop: 20, marginBottom: 20}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <input type="checkbox" style={{ marginRight: 8 }} checked={this.state.receiveNews} onChange={(e) => { this.setState({ receiveNews: e.target.checked })}} />
                                    <div className={styles.input_label} style={{ marginBottom: 0 }}>Yes, I would like Kwik Fill to email me upcoming news and events.</div>
                                </div>

                                <div style={{display: 'flex', alignItems: 'center', marginTop: 12}}>
                                    <input type="checkbox" style={{ marginRight: 8 }} checked={this.state.receiveOffers} onChange={(e) => { this.setState({ receiveOffers: e.target.checked })}} />
                                    <div className={styles.input_label} style={{ marginBottom: 0 }}>Yes, I would like Kwik Fill to email me special offers, coupons, and other exclusive promotions.</div>
                                </div>
                            </div>

                            <div onClick={() => { this.signUp() }} className={styles.primary_button}>Save Changes</div>
                        </div>
                    </>
                }
                

                <div style={{marginTop: 120, marginBottom: 60}}>
                    <AppPromoBanner />
                </div>
            </div>
        );
    }
}
