import AppPromoBanner from "@/components/app-promo-banner";
import React from "react";
import { resetPassword } from '@/services/users';

import styles from './index.module.css';

export default class AccountForgotPassword extends React.Component {
    state = {
        email: '',
    }

    resetPassword() {
        resetPassword(this.state.email);
        alert("Password Reset Sent\n\nCheck your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.");
    }

    render() {
        return (
            <div className={styles.container}>
                <div>
                    <div className={styles.title}>Forgot Password?</div>
                    <div className={styles.subtitle}>Enter your email address below and if it’s associated with an account we will send you instructions on how to reset your password.</div>
                </div>

                <div>
                    <div className={styles.form_container}>
                        <div>
                            <div className={styles.input_label}>Email</div>
                            <input className={styles.input} placeholder="Enter email" value={this.state.email} onChange={(event) => {
                                this.setState({email: event.target.value});
                            }} />
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={styles.primary_button} onClick={() => this.resetPassword()}>Reset Password</div>
                        <div style={{flex: 1}}></div>
                    </div>
                </div>

                <div style={{marginTop: 120, marginBottom: 60}}>
                    <AppPromoBanner />
                </div>
            </div>
        );
    }
}
