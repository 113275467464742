export const universalFleet = `
# United Universal Fleet
<img id="image-full" src="/assets/secondary/united-universal-fleet.jpg"> 
The United Universal Fleet Card is for those who manage fleets that routinely travel outside the United Refining Company market area. You can use the card nationwide and still save 5 cents per gallon when used at any United Refining Company retail location including:Kwik Fill/Red Apple, Country Fair, and Keystone.
</br></br>
<a href="https://onlineservices.secure.force.com/creditapplication/WexBOCA?pgm=UnitedUniversalFleet" id="button">Apply Online</a>
</br></br> 
Click <a href="https://onlineservices.secure.force.com/creditapplication/WexBOCA?pgm=UnitedUniversalFleet">here</a> to access your United Universal Card account.
<a href="">Terms and Conditions</a>
</br></br> 
  Call our Fleet Department for more details - 800.443.3523 ext. 4837 or you can send us a note using this communication form.</a>
<hr \>

### Features
<ul>
<li>
    Wide acceptance outside of United Refining Company's market area. Accepted at over 90% of retail fuel locations (50,000 with diesel) and 45,000 service centers nationwide anywhere Wright Express is accepted.
</li>
<li>    Comprehensive Reporting</li>

<li>    One easy-to-read report including data by vehicle with date, time, miles per gallon and cost per mile</li>
  <li>  Purchase Restrictions - you set the rules for how much or what your drivers can purchase</li>
   <li> Online account management</li>
   <li> More than 99.8% of transactions are captured electronically</li>
   <li> 24x7 customer service with real people</li>
</br>
**PLEASE NOTE:** Requires a one time $40.00 set up fee and carries a $2.00 per card monthly fee. Additional terms and fees may apply to some features.`