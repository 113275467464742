import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
  
export default function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        let [searchParams, setSearchParams] = useSearchParams();

        let [_version, setVersion] = useState(0);

        useEffect(() => {
            setVersion((prevVersion: number) => prevVersion + 1);
        }, [location]);

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [location.pathname]);

        useEffect(() => {
            if (location.pathname === "/careers") {
            // Dynamically create a script element
            const script = document.createElement("script");

            // Set the source of the script to your external JS file
            script.src = "./assets/scripts/olivia.js";

            // Optionally set any other attributes you need
            script.async = true;

            // Append the script to the document's body
            document.body.appendChild(script);

            // Cleanup: Remove the script when this component is unmounted
            return () => {
                document.body.removeChild(script);
            };
            }
        }, [location.pathname]);

        return (
            <Component
                {...props}
                router={{ location, navigate, params, searchParams, setSearchParams }}
            />
        );
    }
  
    return ComponentWithRouterProp;
}
