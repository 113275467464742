export const drivingAmerica = `
# Driving America
<div id="video"> https://www.youtube.com/embed/I0mkTDIUifM </div>
</br></br>
United Refining Company, founded in 1902, refines North America crude oil at its Pennsylvania refinery into quality petroleum products sold through most Kwik Fill locations. When you buy United Refining Company gasoline at Kwik Fill, you can be assured your money is not going overseas.

This benefits over 2500 Kwik Fill employees in New York, Pennsylvania, and Ohio which helps the communities in which they live. By helping local businesses and economies, we keep jobs in America and keep America strong.

Our businesses, our families, and our future depend upon a strong America. Help to keep America strong by buying American-made gasoline at Kwik Fill.

 Striving for Energy Independence from Overseas Oil!
 </br></br>

<img id="image-full" src="/assets/secondary/driving-america.png" />


</br></br>
### Frequently Asked Question
</br>
<b>If Kwik Fill gasoline is not made from overseas oil and is refined here in America, why is it the same price as gas made from overseas oil? Shouldn't it cost less if the oil doesn't come from the other side of the globe? </b>
</br>
This is a great question and one that we get asked often. The short answer is that the crude oil we buy is sold to us at an internationally traded commodity price that is set globally. Where (or when) that oil came out of the ground has no bearing on the price per barrel. Many people assume that North American crude oil would cost less than overseas oil, but that is not the case.

When you buy United Refining Company fuels at Kwik Fill, you are buying a product refined right here in the United States, and your money is not going overseas. That helps the 2500 employees in New York, Ohio, and Pennsylvania, which helps the communities in which they live. And that helps keep America strong.


`