import React from "react";
import withRouter from "@/components/functional-hook-wrapper";
import parse from 'html-react-parser';
import styles from './tertiary.module.css';
import NavigationFooter from "@/components/navigation-footer/navigation-footer";
import NavigationBanner from "@/components/navigation-banner/navigation-banner";
import NavigationHeader from "@/components/navigation-header/navigation-header";
import { chargeCard } from "./pages/charge-card";
import { fleetFuelingCard } from "./pages/fleet-fueling";
// import { fleetFuelingPlus } from "./pages/fleet-fueling-plus";
// import { universalFleet } from "./pages/united-universal-fleet";
// import { giftCards } from "./pages/gift-cards-balance";
// import { rewards } from "./pages/rewards";
// import { bradleyStreetCafe } from "./pages/bradley-street-cafe";
import { Navigate } from "react-router";
import InnerHTML from 'dangerously-set-html-content'



export type TertiaryPageProps = {
    content?: string;
}

export class TertiaryPage extends React.Component<TertiaryPageProps> {
    state = {
        test_content: ``,
        path: ''
    }

    router: any;

    constructor(props: any) {
        super(props);

        this.router = props.router;

        const href = new URLSearchParams(props.router.location.pathname);
        this.setState({ path: href });
    }

    componentDidUpdate(prevProps: any) {
        if (this.router.location?.pathname !== prevProps.router.location?.pathname) {
            const href = new URLSearchParams(prevProps.router.location.pathname) + '';

            if (href !== this.state.path) {
                this.setState({ path: href })
                this.router.navigate(0);
            }
        }
    }

    private getBreadcrumbs(): string {
        let route = this.router.location.pathname.replace('/frame/', '');  
        
        switch (route) {
            case 'charge-card':
                return 'Charge Card'
            case 'fleet-fueling':
                return 'Fleet Fueling'
        default:
            return ''
        }
    }
    
    private getContentFromRoute(): string {
        var output = '';

        let route = this.router.location.pathname.replace('/frame/', '');   
             
        switch (route) {
            case 'charge-card':
                output = chargeCard;
                break;
            case 'fleet-fueling':
                output = fleetFuelingCard;
                break;
            case 'test':
                output = `
                # Test Page Title
                <br>
                ## Subtitle
                ### Sub-sub-title
                normal text
                **bold text**
                *italic*
                <br>
                <img id="image" src="http://localhost:3000/assets/navigation/header_logo.svg" />
                <img id="image-full" src="http://localhost:3000/assets/navigation/header_logo.svg" />
                <a href="https://www.google.com" id="button">Test</a><a href="https://yahoo.com" id="button">Test 2</a>
                <hr />
                <div id="video">https://www.youtube.com/embed/fHhMcb_3IZQ</div>
                <ul><li>One</li><li>Two</li></ul>
                `
                break;
            default:
                break;
        }

        return output;
    }

    htmlToReactWithReplace(): string {
        var toHTML = this.getContentFromRoute();

        return toHTML
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.navigation_container}>
                    <NavigationBanner />
                    <NavigationHeader opacity_scroll={false} />
                </div>
                
                <div className={styles.content_container}>
                    <div className={styles.inner}>
                        <div className={styles.breadcrumb}>{this.getBreadcrumbs()}</div>
                        <InnerHTML html={this.htmlToReactWithReplace()} />
                    </div>
                </div>

                <NavigationFooter />
            </div>
        );
    }
}

export default withRouter(TertiaryPage);
