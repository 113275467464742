import React from "react";

import NavigationBanner from "@/components/navigation-banner/navigation-banner";
import NavigationFooter from "@/components/navigation-footer/navigation-footer";
import NavigationHeader from "@/components/navigation-header/navigation-header";

import HomeCafe from "./cafe/cafe";
import Hero from "./hero/hero";
import HomeInstoreOffers from "./instore-offers/instore-offers";
import HomeOffers from "./offers/offers";
import HomeSocial from "./social/social";

import styles from './home.module.css';

export default class Home extends React.Component {
    state = {
        
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.navigation_container}>
                    <NavigationBanner />
                    <NavigationHeader opacity_scroll={true} />
                </div>
                
                <div style={{marginTop: -89}}>
                    <Hero />
                    <HomeOffers />
                    {/* <HomeInstoreOffers /> */}
                    <HomeCafe 
                        title_top={'Bradley Street Cafe'}
                        body_top={'Bradley Street Cafe is the perfect place to start your day or take a quick break. The cafe is conveniently located in select Kwik Fill locations, making it a quick and easy stop for anyone on-the-go. Whether you\'re in need of a caffeine fix, a quick bite, or a moment of peace and quiet, Bradley Street Cafe has you covered.'} 
                        cta_text_top={'View Details'}
                        cta_text_link_top={'/bradley-street-cafe'}

                        title_bot={'Bradley Street Coffee'}
                        body_bot={'If you\'re in need of a pick-me-up while on the go, look no further than Kwik Fill\'s selection of Bradley Street coffee. This premium coffee brand is carefully crafted to deliver a rich, smooth, and flavorful cup of coffee in every sip.'} 
                        cta_text_bot={'View Locations'}
                        cta_text_link_bot={'/stores?filter=bradleystreetcoffee'}
                    />
                    <HomeSocial />
                </div>

                <NavigationFooter />
            </div>
        );
    }
}
