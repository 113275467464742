export const fleetFueling = `
# Fleet Fueling Card
<div id="video"> https://www.youtube.com/embed/vU-8eVy3FY8 </div>
</br>
Whether you have 1 vehicle or 100, Kwik Fill's Fueling Program puts you in the driver's seat. It's the smart way to control your fleet transportation costs, increase your management efficiency, and improve your bottom line.
</br></br>
<a href="/fleet-card.html" id="button" target="_blank">Apply Online</a>
</br></br> 
Print application and mail back to us.
<a href="/assets/pdf/KF-Charge-Card-Terms-and-Conditions.pdf">Terms and Conditions</a>
<hr \>
For more information contact our Fleet Department by phone 800.443.3523 ext. 4837 or
send a message to "Card Services - Fleet Fueling Cards" using our <a href="/contact-us">communication form.</a>
</br></br> 

<img id="image" width="45%" src="https://i.imgur.com/NTlL797.png" />

<ul>
<li>Save 5 cents per gallon on all Gasoline, Diesel and Kerosene Purchases</li>
<li>Discount will be given right at the pump</li>
<li>Begin your purchase with a Kwik Rewards Club Card* to save an additional 3 cents per gallon (3+5=8)</li>
</ul>
</br>
*Learn more about the Kwik Rewards Club Card here
<hr \>

<ul>
<li>No fees, dues or added charges for credit purchases. You only pay the low pump price.</li>
<li>Nearly 300 company owned and operated locations in New York, Pennsylvania and Ohio - including major truck stops.</li>
<li>Kwik Fill offers the highest grades of gasoline and diesel fuel available. These products are backed by a quality assurance program that runs all the way from the company's own refinery to your tank.</li>
<li>Accurate, easy to understand, itemized statements that consolidate all purchases.</li>
<li>Can be issued weekly, bi-weekly, or monthly.</li>
<li>Simple fast transaction procedures. Your drivers simply sign their receipts and are on the road again.</li>
<li>Accepted at all Kwik Fill, Country Fair, and Keystone locations.</li>
</ul> </br></br>
<a href="/fleet-card.html" id="button" target="_blank">Apply Online</a>
`