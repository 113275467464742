const axios = require('axios').default;

const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const axios_instance = axios.create({
    // baseURL: isDevelopment ? '' : 'https://kwik.gocodeless.com/api/v1',
    baseURL: isDevelopment ? '' : window.location.origin + '/api/v1',
    responseType: 'json',
});

axios_instance.interceptors.response.use((response: any) => response,
    async (error: any) => {
        let token = localStorage.getItem('token');

        if (error?.response?.status === 401 && token.length) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            window.location.href = '/account/login'
        }

        return Promise.reject(error);
    }
);
  
axios_instance.interceptors.request.use(async (request: any) => {
    let token = localStorage.getItem('token');
    
    if (token) {
        request.headers['x-auth-token'] = token;
    }
    
    request.headers['Access-Control-Allow-Origin'] = '*';
    request.headers['Content-Type'] = 'application/json';
    request.headers['Accept'] = 'application/json';
    request.headers['x-api-key'] = 'WjSyKCnwXxHx'

    return request;
});

export default axios_instance;