export const rewards = `
# Kwik Fill Rewards Club

<div id="video"> https://www.youtube.com/embed/1O-nDKO3TTs</div>
</br>
<ul>

<li>Pick one up at your local Kwik Fill</li>
<li>Save 3 cents Per Gallon Instantly</li>
<li>No Need to Track Points</li>
<li>No Registration Required</li>
</ul>
</br></br>
Use your Kwik Rewards Club Card along with your Kwik Fill Charge Card to increase your savings
to 8 cents per gallon at the pump (no need to wait until your statement to see the savings!)

If you don't have a Kwik Fill Charge Card, Click Here to apply for one today!
</br></br>
View <a href="/assets/pdf/kwikfill-rewards-terms.pdf">Terms and Conditions</a>
`

// **SIGN UP TO BECOME A KWIK REWARDS VIP AND RECEIVE EXCLUSIVE COUPON OFFERS!**
// </br>
// Create a Kwik Fill website account (sign in if you already have one) and add your Kwik Rewards Club Card number to receive additional discounts and offers sent via SMS Text Message or Email to your Smartphone Device.

// Important Note: It is not necessary to register a Kwik Rewards Club Card in order to use it. Registering your rewards card in our VIP program simply allows you to receive special discounts and offers that will be delivered electronically through email messages or SMS text messages to your smartphone. As a Kwik Rewards VIP, each offer you receive will include a coupon barcode to be displayed on your smartphone and scanned at the register at the time that the coupon is redeemed. We are not currently able to deliver paper coupons to our Kwik Rewards VIP members.


// ATTENTION. To improve our Kwik Rewards Club VIP program, we are developing a better way of delivering monthly VIP coupon offers. This new delivery method will be available inside our brand new Kwik Fill Mobile App coming soon! Become a Kwik Rewards Club VIP today and watch for the launch of our new Mobile App (May 2021).
// </br></br>
// <div id="button" href="https://www.kwikfill.com/account/vip">Apply Now</div>