import React from "react";
import NavigationBanner from "@/components/navigation-banner/navigation-banner";
import NavigationFooter from "@/components/navigation-footer/navigation-footer";
import NavigationHeader from "@/components/navigation-header/navigation-header";
import styles from './index.module.css';

// Define the structure of an offer
interface Offer {
    image: string;
    price: string;
    title: string;
    description: string;
    category: string;
}

// Define the structure of the component's state
interface State {
    allOffers: Offer[];
    filteredOffers: Offer[];
    selectedPill: string;
}

export default class InStoreOffers extends React.Component<{}, State> {
    state: State = {
        allOffers: [],
        filteredOffers: [],
        selectedPill: 'All',
    }

    componentDidMount() {
        this.fetchOffers();
    }

    private async fetchOffers() {
        try {
            const response = await fetch('https://compact-tan.cmd.outerbase.io/offers');
            const data = await response.json();
            if (data.success) {
                this.setState({ allOffers: data.response.items, filteredOffers: data.response.items });
            }
        } catch (error) {
            console.error('Error fetching offers:', error);
        }
    }

    private filterPills(filter: string) {
        this.setState({
            filteredOffers: filter === 'All' ? this.state.allOffers : this.state.allOffers.filter(offer => offer.category === filter),
            selectedPill: filter
        });
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.navigation_container}>
                    <NavigationBanner />
                    <NavigationHeader />
                </div>

                <div className={styles.content_container}>
                    <div className={styles.section}>Stores /</div>
                    <div className={styles.header}>In-Store Offers</div>
                    <div className={styles.pill_container}>
                        <div className={[styles.pill, this.state.selectedPill === "All" ? styles.pill_selected : ''].join(' ')} onClick={() => this.filterPills("All")}>
                            All
                        </div>
                        <div className={[styles.pill, this.state.selectedPill === "Bradley Street Cafe" && styles.pill_selected].join(' ')} onClick={() => {
                                this.filterPills("Bradley Street Cafe");
                            }}>
                                Bradley Street Cafe
                            </div>
                            <div className={[styles.pill, this.state.selectedPill === "General Merchandise" && styles.pill_selected].join(' ')} onClick={() => {
                                this.filterPills("General Merchandise");
                            }}>
                                General Merchandise
                            </div>
                            <div className={[styles.pill, this.state.selectedPill === "Charlie Biggs' Chicken" && styles.pill_selected].join(' ')} onClick={() => {
                                this.filterPills("Charlie Biggs' Chicken");
                            }}>
                                Charlie Biggs' Chicken
                            </div>

                    </div>

                    <div className={styles.card_container}>
                        {this.state.filteredOffers.map((item, index) => (
                            <div key={index} className={styles.card}>
                                <div>
                                    <img src={`${item.image}`} className={styles.image} alt={item.description} />
                                </div>
                                <div className={styles.price}>{item.price}</div>
                                <div className={styles.title}>{item.title}</div>
                                <div className={[styles.title, styles.description].join(' ')}>{item.description}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <NavigationFooter />
            </div>
        );
    }
}

