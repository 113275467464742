export const kwikPay = `
# KwikPAY
<img id="image" width="45%" src="/assets/secondary/kwikpay.png" />
</br> 
You can view your bill’s summary, make a quick one-time payment, view the status of your payments, schedule recurring payments, and manage your online account below.
</br> </br> 
<a href="https://ww2.payerexpress.com/ebp/UPA/Login/Index" target="_blank" id="button">Proceed to KwikPay</a>
</br></br>
<a href="/assets/pdf/2023-08-11-KWIKPAY-POLICY-STATEMENT.pdf">KWIKPAY Policy Statement</a></br></br>
For help with KwikPAY, <a href="https://kwikfill.com/contact-us">contact</a> our Card Services Department.
</br></br>
You can view your bill’s summary, make a quick one-time payment, view the status of your payments, schedule recurring payments, and manage your online account below.</br> 
`