import React from "react";
import withRouter from "@/components/functional-hook-wrapper";
import parse from 'html-react-parser';
import styles from './secondary.module.css';
import NavigationFooter from "@/components/navigation-footer/navigation-footer";
import NavigationBanner from "@/components/navigation-banner/navigation-banner";
import NavigationHeader from "@/components/navigation-header/navigation-header";
import { chargeCard } from "./pages/charge-card";
import { fleetFueling } from "./pages/fleet-fueling-card";
import { fleetFuelingPlus } from "./pages/fleet-fueling-plus";
import { universalFleet } from "./pages/united-universal-fleet";
import { giftCards } from "./pages/gift-cards-balance";
import { rewards } from "./pages/rewards";
import { bradleyStreetCafe } from "./pages/bradley-street-cafe";
import { Navigate } from "react-router";
import { truckStops } from "./pages/truck-stops";
import { charities } from "./pages/charities";
import { drivingAmerica } from "./pages/driving-america";
import { historyKwikFill } from "./pages/history-of-kwik-fill";
import { historyUrc } from "./pages/history-of-united-refining-company";
import { fuelingDiscovery } from "./pages/fueling-discovery";
import { operationGiveThanks } from "./pages/operation-give-thanks";
import { kwikPay } from "./pages/kwikpay";
import { careers } from "./pages/careers";
import { privacyPolicy } from "./pages/privacy-policy";
import { termsOfUse } from "./pages/terms-of-use";
import { win } from "./pages/win";
import { news } from "./pages/news";




export type SecondaryPageProps = {
    content?: string;
}

export class SecondaryPage extends React.Component<SecondaryPageProps> {
    state = {
        test_content: ``,
        path: '',
        is_logged_in: localStorage.getItem('token')?.length,

    }

    router: any;

    constructor(props: any) {
        super(props);

        this.router = props.router;

        const href = new URLSearchParams(props.router.location.pathname);
        this.setState({ path: href });
    }

    componentDidUpdate(prevProps: any) {
        if (this.router.location?.pathname !== prevProps.router.location?.pathname) {
            const href = new URLSearchParams(prevProps.router.location.pathname) + '';

            if (href !== this.state.path) {
                this.setState({ path: href })
                this.router.navigate(0);
            }
        }
    }

    private getBreadcrumbs(): string {
        // let route = '/charge-card';
        
        // switch (route) {
        //     case '/charge-card':
        //         return 'Charge Card'
        // default:
        //     return ''
        // }

        return ''
    }
    
    private getContentFromRoute(): string {
        var output = '';

        let route = this.router.location.pathname.replace('/page', '');        
        switch (route) {
            case '/charge-card':
                output = chargeCard
                break;
            case '/fleet-fueling-card':
                output = fleetFueling
                break;
            case '/fleet-fueling-plus':
                output = fleetFuelingPlus
                break;
            case '/united-universal-fleet':
                output = universalFleet
                break;
            case '/gift-cards-balance':
                output = giftCards
                break;
            case '/rewards':
                output = rewards
                break;
            case '/bradley-street-cafe':
                output = bradleyStreetCafe
                break;
            case '/truck-stops':
                output = truckStops
                break;
            case '/charities':
                output = charities
                break;
            case '/history-of-kwik-fill':
                output = historyKwikFill
                break;
            case '/history-of-united-refining-company':
                output = historyUrc
                break;
            case '/driving-america':
                output = drivingAmerica
                break;
            case '/fueling-discovery':
                output = fuelingDiscovery
                break;
            case '/operation-give-thanks':
                output = operationGiveThanks
                break;
            case '/kwikpay':
                output = kwikPay
                break;
            case '/careers':
                output = careers
                break;
            case '/privacy-policy':
                output = privacyPolicy
                break;
            case '/terms-of-use':
                output = termsOfUse
                break;
            case '/win':
                output = win
                break;
            case '/news':
                output = news
                break;
                
            // Add Secondary Pages:
            // 1. Create a new file in /secondary/pages folder named for the secondary page (e.g. `charge-card.tsx`)
            // 2. See that file for an example of how to export a string value
            // 3. Import that string file into this file (see `chargeCard` import at top of this file)
            // 4. Add a new case statement as seen above/below
            // 5. Test by visiting localhost:3000/page/{your-case-title-here}
            //
            // case '/my-page-url':
            //     output = exportedStringValue
            //     break;
            case 'test':
                output = `
                # Test Page Title
                <br>
                ## Subtitle
                ### Sub-sub-title
                normal text
                **bold text**
                *italic*
                <br>
                <img id="image" src="http://localhost:3000/assets/navigation/header_logo.svg" />
                <img id="image-full" src="http://localhost:3000/assets/navigation/header_logo.svg" />
                <a href="https://www.google.com" id="button">Test</a><a href="https://yahoo.com" id="button">Test 2</a>
                <hr />
                <div id="video">https://www.youtube.com/embed/fHhMcb_3IZQ</div>
                <ul><li>One</li><li>Two</li></ul>
                `
                break;
            default:
                break;
        }

        return output;
    }

    htmlToReactWithReplace() {
        // `
        // # Test Page Title
        // <br>
        // ## Subtitle
        // ### Sub-sub-title
        // normal text
        // **bold text**
        // *italic*
        // <br>
        // <img id="image" src="http://localhost:3000/assets/navigation/header_logo.svg" />
        // <img id="image-full" src="http://localhost:3000/assets/navigation/header_logo.svg" />
        // <a href="https://www.google.com" id="button">Test</a><a href="https://yahoo.com" id="button">Test 2</a>
        // <hr />
        // <div id="video">https://www.youtube.com/embed/fHhMcb_3IZQ</div>
        // `

        var toHTML = this.state.test_content.length ? this.state.test_content : this.props.content ? this.props.content : this.getContentFromRoute();

        toHTML = toHTML.replace(/### (.*$)/gim, '<h3>$1</h3>'); // h3 tag
        toHTML = toHTML.replace(/## (.*$)/gim, '<h2>$1</h2>'); // h2 tag
        toHTML = toHTML.replace(/# (.*$)/gim, '<h1>$1</h1>'); // h1 tag
        toHTML = toHTML.replace(/\*\*(.*)\*\*/gim, '<b>$1</b>'); // bold tag
        toHTML = toHTML.replace(/\*(.*)\*/gim, '<i>$1</i>'); // italic text
        toHTML = toHTML.replace(/\[([^\[]+)\](\(.*\))/gim, (result) => {
            const regex_https = /^\[([\w\s\d]+)\]\(((?:\/|https?:\/\/)[\w\d./?=#]+)\)$/
            const regex_mailto_0 = /^\[(.*?)\]/gim
            const regex_mailto_1 = /mailto:(.*$)/gim

            const match = result.match(regex_https);

            if (!match) {
                const match_mailto = result.match(regex_mailto_1);
                
                if (match_mailto) {
                    const match_mailto_text = result.match(regex_mailto_0);
                    let text_match = match_mailto_text[0];
                    let text = text_match.substring(1, text_match.length - 1);

                    let email_match = match_mailto[0]
                    let email = match_mailto[0].substring(0, email_match.length - 2)
                    return `<a href="${email}">${text}</a>`
                }
            }
            
            if (!match || match?.length < 3) { return '' }
            return `<a href="${match[2]}">${match[1]}</a>`
        });

        const replace = (domNode: any) => {
            if (domNode.attribs && domNode.attribs.id === 'logged-in') {
                return this.state.is_logged_in ? domNode : <></>
            }

            if (domNode.attribs && domNode.attribs.id === 'logged-out') {
                return !this.state.is_logged_in ? domNode : <></>
            }

            if (domNode.attribs && domNode.attribs.id === 'image') {
                let data = domNode.attribs.src
                return (
                    <img className={styles.img} id="image" src={data} />
                );
            }

            if (domNode.attribs && domNode.attribs.id === 'image-full') {
                let data = domNode.attribs.src
                return (
                    <img className={styles.img} id="image-full" style={{ width: '100%' }} src={data} />
                );
            }

            if (domNode.attribs && domNode.attribs.id === 'button' && domNode.children.length) {
                let text = domNode.children[0].data
                let href = domNode.children[0].parent.attribs.href
                
                return (
                    <a href={href} className={styles.button}>{text}</a>
                );
            }

            if (domNode.attribs && domNode.attribs.id === 'video') {
                let url = domNode.children[0]?.data
                
                return (
                    <div className={styles.iframe_container}>
                        <iframe className={styles.iframe} src={url} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                );
            }
        };

        return parse(toHTML, { replace });
    }


    render() {
        return (
            <div className={styles.container}>
                <div className={styles.navigation_container}>
                    <NavigationBanner />
                    <NavigationHeader opacity_scroll={false} />
                </div>
                
                <div className={styles.content_container}>
                    {/* <textarea style={{ width: '100%', height: 200 }} value={this.state.test_content} onChange={(event) => {
                        this.setState({test_content: event.target.value});
                    }} /> */}

                    <div className={styles.breadcrumb}>{this.getBreadcrumbs()}</div>
                    {this.htmlToReactWithReplace()}
                </div>

                <NavigationFooter />
            </div>
        );
    }
}

export default withRouter(SecondaryPage);
