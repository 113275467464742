import AppPromoBanner from "@/components/app-promo-banner";
import React from "react";
import withRouter from "@/components/functional-hook-wrapper";

import styles from './index.module.css';

export class HelpFAQ extends React.Component {
    router: any
    faqs = [
        {  
            "sectionHeader": "General Questions about Kwik Fill",
            "sectionData" : [
                {
                    "accordianHeader": "What is the difference between Kwik Fill and Red Apple?",
                    "accordianBody": "Kwik Fill® denotes our chain of locations as a whole and also represents our brand of fuel. Red Apple Food Marts® is what we call our convenience stores. All of our locations go by the name Kwik Fill, but not all of them have a Red Apple® convenience store. Some of our locations are full-serve gas stations."
                },
                {
                    "accordianHeader": "Is it true that United Refining Company's Kwik Fill Brand gasoline is made in America?",
                    "accordianBody": "Yes. United Refining Company operates a 70,000 barrel per-day refinery located in Warren, Pennsylvania and markets its American-made gasoline and diesel through its chain of retail outlets."
                },
                {
                    "accordianHeader": "Is it true that Kwik Fill gasoline and diesel are not made from overseas oil?",
                    "accordianBody": "Yes. United Refining Company refines with North American crude oil; no overseas crude oil is used to make Kwik Fill branded fuels."
                },
                {
                    "accordianHeader": "If Kwik Fill gasoline is not made from overseas oil and is refined here in America, why is it the same price as gas made from overseas oil? Shouldn't it cost less if the oil doesn't come from the other side of the globe?",
                    "accordianBody": "This is a great question and one that we get asked often. The short answer is that the crude oil we buy is sold to us at an internationally traded commodity price that is set globally. Where (or when) that oil came out of the ground has no bearing on the price per barrel. Many people assume that North American crude oil would cost less than overseas oil, but that is not the case.\n\nWhen you buy United Refining Company fuels at Kwik Fill, you are buying a product refined right here in the United States, and your money is not going overseas. That helps the 2500 employees in New York, Ohio, and Pennsylvania, which helps the communities in which they live. And that helps keep America strong."
                },
            ]
        },
        {
            "sectionHeader": "Questions about Credit Card Transactions (other than Kwik Fill Cards)",
            "sectionData": [
                {
                    "accordianHeader": "Why does my bank say that Kwik Fill is \"holding\" pre-authorization funds?",
                    "accordianBody": "This is very important. Pre-Authorization and Hold (Pending) charges are controlled and applied to consumer accounts by the institution (bank, etc.) associated with the card. These charges are not controlled by Kwik Fill. We do not place holds on customer accounts. We follow the rules and guidelines of the card associations (i.e. Visa, MasterCard, etc.) in the manner that we obtain card validation prior to turning on the gas pump.\n\nIn ordinary retail transactions where a card is used for payment, the seller knows the sale amount and charges that amount to the customer's account. When buying gasoline at the pump, the transaction needs to be pre-authorized BEFORE the pump will dispense fuel because the total dollar amount of the fuel sale is not yet known. The institution associated with the card determines that pre-authorization amount and how long it will show as \"Pending\" on the account. Some institutions can take 3-5 days to release the pre-authorization.\n\nYour financial institution should be able to provide you with their policies. These are typically found in a document titled \"Deposit Account Agreement\" or \"Electronic Funds Transfer Agreement\". Credit card account documents may be called a \"Truth in Lending\" or a \"Credit Account Agreement\".\n\nThis information will not help you very much if there is a hold on your account, but hopefully you now understand why it's there, and that may help you with any conversation you might have with your bank."
                },
            ]
        },
        {
            "sectionHeader": "Questions about Kwik Fill Charge Cards, Fleet Fueling Cards, and KwikPAY",
            "sectionData": [
                {
                    "accordianHeader": "How do I change my name, address, and remove joint applicant on my account?",
                    "accordianBody": "Name changes and joint applicant removals require documents that can be mailed to you, or you can download them from our Contact Us page and send them to us at the address provided. If you are changing your address, either print the address change form from online, complete and mail it back, or use that as a template to provide your address change by email."
                },
                {
                    "accordianHeader": "What do I do if I'm having trouble logging in after enrolling for online account?",
                    "accordianBody": "If you receive a disabled account message, please check your email. Enrollment Activation must be completed to be able to log on. The Enrollment Activation email sometimes can take a couple hours to receive, and might end up in your spam/junk mail box. If your account is locked out, you will have to call our office to get unlocked. If you get the message \"no data base or account information on file,\" you have either not enrolled or you are entering your account number and zip code incorrectly or in the wrong section."
                },
                {
                    "accordianHeader": "What do I do if my payment does not appear to be applied to my account?",
                    "accordianBody": "Call the customer service number on your statement to verify that we did not receive it after the statement was generated; it might be applied now. If it has not cleared your bank, chances are we did not receive it. If it cleared your bank and the customer service representative does not show it as being applied to your account, fax us a copy of the front and back of the check with your 8 digit account number to 814-726-7610."
                },
                {
                    "accordianHeader": "What do I do if my payment has not come out of my bank account?",
                    "accordianBody": "If you are already enrolled in KwikPAY, go to \"Search and Pay My Bills\" to review if payment was authorized and processed. If it does not show that a payment was processed, resubmit your payment and be sure to click the final “PAYNOW” button on the AUTHORIZATION page. If the \"Search and Pay My Bills\" shows the payment as being processed but returned, confirm with your bank that you have the correct bank account number needed to process a payment online, and then update your bank account information prior to reprocessing the payment again."
                },
                {
                    "accordianHeader": "What if I want my payment withdrawn from my bank account automatically each month?",
                    "accordianBody": "Recurring payments are only allowed on enrolled accounts and they are withdrawn at the beginning of the month when the statements are generated. This could occur between the 2nd and the 6th of the month. To have the statement balance withdrawn from your account each month you will need to enroll in KwikPAY and enter your banking information. You can sign up for recurring payments at this time. Please note that your first automatic payment will begin the following month. You will need to make your first payment online. Please be sure to not select a date, as that date is just for when the recurring payments will begin. For example, if you sign up for automatic payments in February and select March 25th as the date to begin payment, the payment will not actually be deducted from the account until the April statement. This is because the first automatic payment will not begin until the following month. If March is selected as the date of payment, than the computer system will process the first automatic payment one month later (April), even though the payments were set up in February."
                },
                {
                    "accordianHeader": "What if my statement is not showing online?",
                    "accordianBody": "If you received a paper statement in the mail, but want to pay the bill online, you may have to click on the view button, double click the dollar amount, and change it to match your current bill. Click the big blue \"PAYNOW\" button to process your payment on time. If you have recently moved, please call our office so we can update your zip code on future statements."
                },
                {
                    "accordianHeader": "When should I pay my bill?",
                    "accordianBody": "You should always plan on mailing your payment at least one week prior to the 25th, as long as the 25th is a weekday. If you are paying online or by phone you should do so no later than noon on the 22nd in order to ensure that the payment is received on time. If you are processing a payment online through your bank's bill-pay system, please remember to allow for mailing time as most bank bill- pay systems actually mail a check to us. You should process your payment as soon as you receive your bill, unless you can get confirmation from your bank as to when they mail checks."
                },
                {
                    "accordianHeader": "Where did the service charge on my account come from?",
                    "accordianBody": "Send an email to cards@kwifill.com describing the service charge in question and we will respond with the information via email."
                },
                {
                    "accordianHeader": "Where do I find my account balance?",
                    "accordianBody": "After the statements are generated (around the 5th of each month) you can find your balance online at the KwikPAY link at urc.com or kwikfill.com. You will need your 8 digit account number and the last 4 digits of your 5 digit zip code (if you are not an enrolled customer) and you can enter these items in the PAYNOW section of payment processing center page. The statements come up on the screen newest to oldest and only holds one year."
                },
            ]
        },
        {
            "sectionHeader": "General Questions about Kwik Fill products and services",
            "sectionData": [
                {
                    "accordianHeader": "Can I add money back on to a Kwik Fill Gift Card?",
                    "accordianBody": "Yes. You can add money back on to an already activated Kwik Fill Gift Card at any Kwik Fill/Red Apple location, or you may call our Customer Service Department during normal business hours at 800-443-3523, extension 4762."
                },
                {
                    "accordianHeader": "Do you sell cigarettes at state minimum pricing?",
                    "accordianBody": "Any location which displays a sign \"Lowest price by law\" will tell you we are at state min. at that store. All cigarette cartons are sold at state minimum pricing regardless of state."
                },
                {
                    "accordianHeader": "Do you sell milk at state minimum pricing in the state of Pennsylvania?",
                    "accordianBody": "Yes. We sell all of our 1/2 gallons and gallons of milk at state minimum pricing."
                },
                {
                    "accordianHeader": "How do I check the balance on my Kwik Fill Gift Card?",
                    "accordianBody": "You can check the balance on your Kwik Fill Gift Card at https://kwikfill.com/gift-cards-balance. You will need the account number and pin number from the back of the card. Not only will this give you a balance, but it will also tell you where and when your card was activated and used and the cost of each transaction on the card. You can also check the balance on your Kwik Fill Gift Card by calling our toll-free number at 866-292-6027."
                },
                {
                    "accordianHeader": "In what way do you guarentee your coffee?",
                    "accordianBody": "We guarantee we will make fresh coffee every 30 minutes. If you find we don't, we will make you a fresh pot on the spot."
                },
            ]
        },
        {
            "sectionHeader": "Questions Regarding Kwik Fill Rewards",
            "sectionData": [
                {
                    "accordianHeader": "On which kinds of fuels can I save with the Kwik Rewards Club Card?",
                    "accordianBody": "All grades of gasoline and diesel."
                },
                {
                    "accordianHeader": "What do I do if I've lost my Kwik Rewards Club Card?",
                    "accordianBody": "Because the rewards are instant, there is no “balance” associated with the card. Just go to Kwik Fill and request a new one. If you’ve registered your card to become a VIP member, register your new card to continue receiving VIP benefits."
                },
                {
                    "accordianHeader": "What's the catch?",
                    "accordianBody": "There's no catch. We want to reward our customers who regularly make American-made Kwik Fill gasoline their first choice in fuel. Loyalty has its rewards!"
                },
                {
                    "accordianHeader": "Where can I learn more?",
                    "accordianBody": "Review program information at kwikfill.com/rewards, use our Customer Service Contact Us page at kwikfill.com, or contact Kwik Fill Customer Service at 800-443-3523 extension 4762."
                },
                {
                    "accordianHeader": "Where can I use my Kwik Rewards Club Card?",
                    "accordianBody": "The Kwik Rewards Card can be used at all company operated Kwik Fill locations as well as participating Kwik Fill Franchise stores. Visit kwikfill.com for our store locator map."
                }
            ]
        }
    ]

    constructor(props: any) {
        super(props)
        this.router = props.router;
    }
      
    state = {
        active_accordian_index: -1,
        active_section_index: -1,
        questions: this.faqs,
    }

    private toggle(index: number, sectionIndex: number) {
        let activeAccordianIndex = this.state.active_accordian_index;
        let activeSectionIndex = this.state.active_section_index;

        if (index === activeAccordianIndex && sectionIndex === activeSectionIndex) {
            this.setState({
                active_accordian_index: -1,
                active_section_index: -1
            });
        } else {
            this.setState({
                active_accordian_index: index,
                active_section_index: sectionIndex
            });
        }
    }

    render() {
        return (
            <div className={styles.container}>
                <div>
                    <div className={styles.title}>Frequently Asked Questions</div>
                    <div className={styles.subtitle}>If you have a question that is not on this list, please use the <div className={styles.subtitle && styles.underline} onClick={() => this.router.navigate('/help')}>contact page</div> and we will respond as quickly as possible.</div>
                </div>

                <div>
                    { this.state.questions && this.state.questions.map((section, sectionIndex) => {
                        let sectionData = section.sectionData.map((item, index) => {
                            return (
                                <div className={styles.accordian_wrapper}>
                                    <div className={styles.accordian_header_wrapper} onClick={() => this.toggle(index, sectionIndex)}>
                                        <div className={styles.accordian_header}>{item.accordianHeader}</div>
                                        <div className={styles.sign}>{this.state.active_accordian_index === index && this.state.active_section_index === sectionIndex ? 'Collapse' : 'Expand'}</div>
                                    </div>
                                    {   this.state.active_accordian_index === index && this.state.active_section_index === sectionIndex && (
                                        <div className={styles.accordian_body}>{item.accordianBody}</div>
                                    )}
                                </div>
                            )
                        });

                        return (
                            <div className={styles.accordian_container}>
                                <div className={styles.section_header}>{section.sectionHeader}</div>
                                <div>{sectionData}</div>
                            </div>
                        )
                    })}
                </div>

                <div style={{marginTop: 120, marginBottom: 60}}>
                    <AppPromoBanner />
                </div>
            </div>
        );
    }
}

export default withRouter(HelpFAQ)