import AppPromoBanner from "@/components/app-promo-banner";
import React from "react";

import styles from './index.module.css';

export default class HelpForms extends React.Component {
    state = {
        
    }

    render() {
        return (
            <div className={styles.container}>
                <div>
                    <div className={styles.title}>Card Services Forms</div>
                    <div className={styles.subtitle}>Thank you for signing in. Using the links on this page, you can enter our giveaways see your entries, and view the winners. You can also update your user information and register to become a Kwik Rewards Club VIP.</div>
                </div>

                <div className={styles.section_container}>
                    <p><a href="/assets/pdf/transactionDisputeForm.pdf">Transaction Dispute Form</a></p>
                    <p><a href="/assets/pdf/Kwik-Fill-Charge-Card-Name-Change-Form.pdf">Name Change Form</a></p>
                    <p><a href="/assets/pdf/newaccountnotice.pdf">New Account Notice</a></p>
                    <p><a href="/assets/pdf/statementdisclaimer.pdf">Statement Disclaimer</a></p>
                    <p><a href="/help">Contact Card Services</a></p>
                </div>

                <div style={{marginTop: 120, marginBottom: 60}}>
                    <AppPromoBanner />
                </div>
            </div>
        );
    }
}
