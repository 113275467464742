import React from "react";

import styles from './base-button.module.css';

export type BaseButtonProps = {
    title: string;
    type: BaseButtonType;
    buttonType?: "button" | "submit" | "reset";
    onClick: () => void;
}

export enum BaseButtonType {
    primary,
    primaryCancel,
    secondary,
}

export default class BaseButton extends React.Component<BaseButtonProps> {

    render() {
        return (
            <>
            {
                this.props.type === BaseButtonType.primary &&
                    <button type={this.props.buttonType ?? 'button'} className={styles.primary} onClick={this.props.onClick}>{this.props.title}</button>
            }
            {
                this.props.type === BaseButtonType.primaryCancel &&
                    <div className={styles.primary_cancel} onClick={this.props.onClick}>{this.props.title}</div>
            }
            {
                this.props.type ===BaseButtonType.secondary &&
                    <div className={styles.secondary} onClick={this.props.onClick}>{this.props.title}</div>
            }
            </>
        )
    }
}