export const privacyPolicy = `
# Privacy Policy
Your privacy is important to us here at Kwik Fill®/Red Apple® and we are committed to protecting your privacy. This Privacy Policy explains what information we may acquire about you, our customer, and how we may use it. We may revise this Policy without prior notice. You can find out when this Policy was last revised by referring to the date at the end of the Policy.This Privacy Policy governs the manner in which United Refining Company ("URC") DBA Kwik Fill®/Red Apple® collects, uses, maintains and discloses information collected from users (each, a "User") of the www.kwikfill.com website ("Site"). This privacy policy applies to the Site and all products and services offered by United Refining Company.This Web site is controlled by United Refining Company (URC) DBA Kwik Fill®/Red Apple® in the United States and is subject to its laws.
</br></br>
## Personal Information</br>
URC does not collect personal information about visitors to our Web Site unless it is provided voluntarily. If you provide personal information to URC via this Web Site, such information will be used only for URC's internal purposes. No information is shared with Third Parties unless expressly noted.
</br>
We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, place an order, respond to a survey, fill out a form, and in connection with other activities, services, features or resources we make available on our Site. As appropriate, users may be asked for: name, email address, mailing address, phone number, and credit card information. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.
</br></br>
## Non-Personally Identifiable Information</br>
We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include: the browser name, the type of computer, and technical information, such as the users' operating systems and Internet service providers. Collecting this kind of information is standard practice on the Internet, and is done automatically by many Web sites.
</br></br>
## Web Browser Cookies</br>
Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
</br></br>
## How we use collected information:</br>
URC collects and uses Users personal information for the following purposes:</br></br>
### To personalize user experience:</br>
We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.
</br></br>
### To improve our Site:
We continually strive to improve our website offerings based on the information and feedback we receive from you.
</br></br>
### To improve customer service:
Your information helps us to more effectively respond to your customer service requests and support needs.
</br></br>
### To process transactions:
We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.
</br></br>
### To administer a content, promotion, survey or other Site feature:
To send Users information they agreed to receive about topics we think will be of interest to them.
</br></br>
### To send periodic emails and SMS:
The email address Users provide for order processing, will only be used to send them information and updates pertaining to their order. It may also be used to respond to their inquiries, and/or other requests or questions. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email or User may contact us via our Site. Users can also opt into receive SMS messages and offers (Message and Data Rates May Apply). Users can opt-out of offers "Text STOP to 64827 to cancel and receive no further messages". Users needing  assistance with SMS offers can contact the Advertising department through our Contact us page.
</br></br>

 

## How we protect your information</br>
We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.Sensitive and private data exchange between the Site and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures. Our Site is also in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users.
</br></br>
### Sharing your personal information</br>
We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.
</br></br>
### Links to Other Sites</br>
Users may find advertising or other content on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website's own terms and policies.URC is not responsible for the privacy policies of sites linked to or from this Web Site and makes no representation as to such sites policies.
</br></br>
### Compliance with children's online privacy protection act</br>
Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at our Site from those we actually know are under 13, and no part of our website is structured to attract anyone under 13.
</br></br>
### Changes to this privacy policy</br>
URC has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
</br></br>
### Your acceptance of these terms</br>
By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
</br></br>
## Contacting us</br>
If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at:
</br></br>
United Refining Company of Pennsylvania</br>
DBA Kwik Fill / Red Apple</br>
814 Lexington Ave</br>
P.O. Box 780</br>
Warren, PA 16365</br></br>

800-443-3523</br>

You may also use our online Communication Form here.</br>

This document was last updated on February 17, 2012
`