export const truckStops = `
# Kwik Fill Truck Stops
<img id="image-full" width="100%" src="/assets/secondary/truck-stops.jpg">
</br></br>
### Kwik Fill operates four fully functional truck stops and four mini truck stops/travel plazas.
</br>
These locations not only provide refueling services, but they also offer areas for parking, ready made food, and other amenities for truck drivers and motorists.
</br></br>
### Full Truck Stops
</br>
<ul>
<li>Kylertown, PA</li>
<li>Geneva, OH</li>
<li>North East, PA</li>
<li>Barkeyville, PA</li>
</ul>
</br></br>
### Scales
</br>
All four Kwik Fill Truck Stop locations offer a certified scale on site. Northeast and Barkeyville use CAT and Geneva and Kylertown have Kwik Fill Certified Scales. Stop by a location today for a reliable, accurate and certified weight.
</br></br>
### Food
</br>
The Kylertown truck stop features The Bradley Street Cafe®, a family style, sit-down diner, which is open 24/7. The Barkeyville Kwik Fill truck stop also has a small restaurant with limited hours. Both Geneva and Northeast truck stops provide access 24/7 to Kwik Fill's own Bradley Street Cafe®.
</br></br>
### Amenities
</br>
The Kylertown truck stop boasts a motel with 30 one-bedroom units. Barkeyville has a small motel. All four major truck stops provide 24/7 access to full serve diesel fuel. The Kylertown and Geneva truck stops have DEF available at the fuel island.
</br></br>
### Mini Truck Stops/Travel Plazas
</br>
Kwik Fill also operates four mini truck stops which are located in:</br>
<ul>
<li>Duncansville, PA </li>
<li>Mechanicsburg, PA</li>
<li>Blossburg, PA</li>
<li>Jamestown, NY</li>
</ul>
</br>
Our Blossburg and Jamestown locations offer Bradley Street Cafe® prepared foods.

For more information, as well as specific store locations, visit our Store Locator page.
`