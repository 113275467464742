import AppPromoBanner from "@/components/app-promo-banner";
import BaseButton, { BaseButtonType } from "@/components/base-button/base-button";
import React from "react";

import styles from './index.module.css';

export default class AccountVIP extends React.Component {
    state = {
        registration_number: '',
        secondary_number: ''
    }

    private submit() {
        // TODO: determine endpoint for this
    }

    render() {
        return (
            <div className={styles.container}>
                <div>
                    <div className={styles.title}>Become a VIP</div>
                    <div className={styles.subtitle}>Thank you for signing in. Using the links on this page, you can enter our giveaways see your entries, and view the winners. You can also update your user information and register to become a Kwik Rewards Club VIP.</div>
                </div>

                <div>
                    <div className={styles.form_container}>
                        <div>
                            <div className={styles.input_label}>Rewards Club VIP Registration</div>
                            <input className={styles.input} placeholder="Enter registration number" value={this.state.registration_number} onChange={(event) => {
                                this.setState({registration_number: event.target.value});
                            }} />
                        </div>
                        
                        <div>
                            <div className={styles.input_label}>Secondary VIP Registration</div>
                            <input className={styles.input} placeholder="Enter secondary registration number" value={this.state.secondary_number} onChange={(event) => {
                                this.setState({secondary_number: event.target.value});
                            }} />
                        </div>
                    </div>

                    <div style={{marginTop: 20}}>
                        <BaseButton type={BaseButtonType.primary} title='Save' onClick={() => {this.submit()}} />
                    </div>

                </div>

                <div style={{marginTop: 120, marginBottom: 60}}>
                    <AppPromoBanner />
                </div>
            </div>
        );
    }
}
