export const fleetFuelingPlus = `
# Fleet Fueling Plus
<img width="100%" id="image-full" src="/assets/secondary/fleet-fueling-plus.jpg" />
</br></br>

Save time and reduce the cost of managing vehicles! Our United Fleet Fueling Plus program has all of the great benefits of our standard fleet program, but with greater convenience and control.
</br></br>
<a href="/assets/pdf/FleetFuelingPlusApplication.pdf" id="button">Print Application</a>
</br></br>
Print application and mail back to us.
Terms and Conditions

For more information contact our Fleet Department by phone 800.443.3523 ext. 4837 or
send a message to "Card Services - Fleet Fueling Cards" using our communication form.
</br></br>
At the heart of the Fleet Fueling Program is our Purchase Activity Report, a comprehensive data tool designed to make fleet management easier. Whether you're tracking expenditures for one vehicle or one hundred, we provide information the way you need it. Reports can be viewed any time at www.urcfleetonline.com for fast, easy access or they can be received via mail.
</br></br>

<ul>
<li>
    Save 5 cents per gallon on all Gasoline, Diesel and Kerosene Purchases
<li>
        Begin your purchase with a Kwik Rewards Club Card* to save an additional 3 cents per gallon (3+5=8)</li></li>
</ul>
</br>
### *Learn more about the Kwik Rewards Club Card here
 </br>
<ul>
<li>
    No fees, dues or added charges for credit purchases. You only pay the low pump price.</li>
<li>
    Nearly 300 company owned and operated locations in New York, Pennsylvania and Ohio - including major truck stops.</li>
<li>
    Kwik Fill offers the highest grades of gasoline and diesel fuel available. These products are backed by a quality assurance program that runs all the way from the company's own refinery to your tank.</li>

</ul>
</br>
### Additional Reporting Features
</br>
Fleet Fueling Plus is a PIN operated program. Each cardholder will have a unique PIN number to be used when purchasing fuel or merchandise. The fleet administrator can control the account via the Secure URC Website (https://www.urcfleetonline.com/online) to monitor usage, at no extra cost. This eliminates the need to sort through cash receipts and credit card slips, eliminating additional administrative expenses and saving you time and money.
</br></br>
### You can see all of this information at a glance:
</br><ul>
    <li>Date, time and location of transaction</li>
    <li>Number of gallons purchased</li>
    <li>Miles per gallon</li>
    <li>Products and/or services purchased</li>
    <li>Total purchase amount</li>
    <li>Exceptions to your fleet policies</li>
    <li>Odometer readings at time of purchase</li>
    <li>Cost per mile</li>
</ul>
</br>
Accepted at all Kwik Fill, Country Fair, and Keystone Locations. 
`