export const termsOfUse = `
# Terms of Use</br>
### Terms of Use for United Refining Company</br></br>

Welcome to United Refining Company's (URC) Kwik Fill®/Red Apple® Internet Web site. We're glad that you've stopped by for a visit. URC's family of brands include Kwik Fill®/Red Apple®, Country Fair® and Keystone®. We maintain this Kwik Fill®/Red Apple® Web site for your general information and education and to communicate corporate activities. Access to and use of this site are subject to the following terms and conditions:
</br></br>
### 1. Copyright and Trademark Notice</br>
</br>
The text and graphics provided on URC’s Web sites are protected by law including copyright law. All rights are reserved. URC does, however, permit visitors to make a single copy of information published on the website for personal, non-commercial use.
</br>
The trademarks, service marks, trade names, logos and other indications of origin displayed within this site are owned by URC, one of its affiliates, or third parties who have granted URC a right to use them generally or within this site. Use of such trademarks, service marks, trade names, logos and other indications of origin is prohibited without the express written permission of URC or third party owner.
</br></br>
### 2. General Disclaimer</br>
</br>
Visitors to URC’s Web sites assume all responsibility and risk for the use of those sites. URC shall endeavor to update its web sites and to provide accurate and timely information. However, URC makes no representation or warranties as to the accuracy, reliability or completeness of the information contained within its sites.
</br>
ALL INFORMATION AND MATERIALS CONTAINED IN THIS SITE ARE PROVIDED TO YOU AS-IS, AND YOU USE THIS SITE AND SUCH INFORMATION AND MATERIAL AT YOUR OWN RISK. URC MAKES NO WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.
</br>
URC SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, INCIDENTAL OR EXEMPLARY DAMAGES ARISING FROM YOUR ACCESS TO OR USE OF THIS SITE OR ANY SITE LINKED TO OR FROM THIS SITE, OR FROM THE USE OF OR RELIANCE ON INFORMATION PRESENTED.
</br></br>
### 3. Privacy</br>
</br>
URC does not collect personal information about visitors to our Web sites unless it is provided voluntarily. If you provide personal information to URC via this web site, such information is covered (governed) by our published privacy policy.
</br></br>
### 4. Links to Third Party Sites</br>
</br>
This site may contain links to third party sites that URC does not own or maintain. Such links are provided for your convenience. In addition, links to URC's sites may be provided from other Web sites either known or unknown to URC. Access to sites linked from URC's Web sites is at your own risk. URC is not responsible for the content of sites linked to or from URC's sites nor the accuracy or reliability of any information, data, opinions, or other material contained on these linked sites.
</br></br>
### 5. Cookies</br>
</br>
Portions of our Sites accept cookies. A cookie is a piece of computer data that the computer reads. It recognizes your Internet address but does not personally identify you. Cookies are used by our Web site server to make your navigation of our Sites easier and more useful. You may turn off cookies by choosing to do so in your Internet browser. By turning off Cookies, you may not have the same experience as you would with the cookies enabled, but you will still be able to access much of the Site.
</br></br>
### 6. Modification of Terms</br>
</br>
These Web site Terms of Use may be modified by URC in its sole discretion at any time. If you have any questions or comments about our terms of use, you can send us a note using our Contact Us page or call our Customer Service depart at 800.443.3523.
</br></br>
`