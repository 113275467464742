import React from "react";
import withRouter from "../functional-hook-wrapper";
import styles from './navigation-header.module.css';

export type NavigationHeaderProps = {
    opacity_scroll?: boolean;
}

export class NavigationHeader extends React.Component<NavigationHeaderProps> {
    state = {
        opacity_scroll: false,
        opacity: 0, 
        currentScrollHeight: 0,

        show_mobile_menu: false,
        show_mobile_stores_options: false,
        show_mobile_cards_options: false,
        show_mobile_win_options: false,
        show_mobile_community_options: false,
        show_mobile_about_options: false,
        show_mobile_contact_options: false,
    }

    router: any;

    constructor(props: any) {
        super(props);

        this.router = props.router;
        this.state.opacity_scroll = props.opacity_scroll
    }

    componentDidMount() {
        window.onscroll = () => {
            const newScrollHeight = Math.ceil(window.scrollY / 50) * 50;
            this.setState({ currentScrollHeight: newScrollHeight });
        };
    }

    private home() {
        window.history.pushState({}, null, '/');
        this.router.navigate('/');
    }


    render() {
        const opacity = this.state.opacity_scroll ? Math.min(this.state.currentScrollHeight / 250, 1) : 1;

        return (
            <>
                { this.state.show_mobile_menu &&
                <div className={styles.mobile_menu_container}>
                    <div className={styles.mobile_menu_header}>
                        <div onClick={() => { this.home() }}>
                            <img src={`${process.env.PUBLIC_URL}/assets/navigation/mobile_header_logo.png`}/>
                        </div>
                        
                        <div style={{ flex: 1 }}></div>
                        <div onClick={() => { this.setState({ show_mobile_menu: false })}}>
                            <img src={`${process.env.PUBLIC_URL}/assets/navigation/mobile_header_close.svg`}/>
                        </div>
                    </div>

                    <div className={styles.mobile_menu_body}>
                        <div style={{ paddingTop: 24, paddingBottom: 24, paddingLeft: 32, paddingRight: 32 }}>
                            <div className={styles.mobile_menu_section}>
                                <div className={styles.mobile_menu_option} onClick={() => { this.setState({ show_mobile_stores_options: !this.state.show_mobile_stores_options })}}>
                                    <div style={{ flex: 1 }}>Stores</div>

                                    { !this.state.show_mobile_stores_options &&
                                    <svg style={{ width: 14, height: 14 }} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.70312 10.0767V0.474431H6.61364V10.0767H4.70312ZM0.860795 6.22727V4.31676H10.4631V6.22727H0.860795Z" fill="#009146"/>
                                    </svg>
                                    }

                                    { this.state.show_mobile_stores_options &&
                                    <svg style={{ width: 14, height: 14 }} width="10" height="3" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 0.933238V2.77273H0V0.933238H10Z" fill="#009146"/>
                                    </svg>
                                    }

                                </div>

                                { this.state.show_mobile_stores_options &&
                                <>
                                    <div onClick={() => { this.router.navigate('/stores'); }} className={styles.mobile_menu_subsection}>
                                        Store Locator
                                    </div>

                                    <div onClick={() => { this.router.navigate('/truck-stops'); }} className={styles.mobile_menu_subsection}>
                                        Truck Stops
                                    </div>

                                    <div onClick={() => { this.router.navigate('/bradley-street-cafe'); }} className={styles.mobile_menu_subsection}>
                                        Bradley Street Cafe
                                    </div>

                                    <div onClick={() => { this.router.navigate('/specials') }} className={styles.mobile_menu_subsection}>
                                        In-Store Specials
                                    </div>

                                    {/* <div className={styles.mobile_menu_subsection}>
                                        Coupons
                                    </div> */}
                                </>
                                }
                            </div>

                            <div className={styles.mobile_menu_section}>
                                <div className={styles.mobile_menu_option} onClick={() => { this.setState({ show_mobile_cards_options: !this.state.show_mobile_cards_options })}}>
                                    <div style={{ flex: 1 }}>Cards</div>

                                    { !this.state.show_mobile_cards_options &&
                                    <svg style={{ width: 14, height: 14 }} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.70312 10.0767V0.474431H6.61364V10.0767H4.70312ZM0.860795 6.22727V4.31676H10.4631V6.22727H0.860795Z" fill="#009146"/>
                                    </svg>
                                    }

                                    { this.state.show_mobile_cards_options &&
                                    <svg style={{ width: 14, height: 14 }} width="10" height="3" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 0.933238V2.77273H0V0.933238H10Z" fill="#009146"/>
                                    </svg>
                                    }

                                </div>

                                { this.state.show_mobile_cards_options &&
                                <>
                                    <div onClick={() => { this.router.navigate('/charge-card') }} className={styles.mobile_menu_subsection}>
                                        Charge Card
                                    </div>

                                    <div onClick={() => { this.router.navigate('/fleet-fueling-card') }} className={styles.mobile_menu_subsection}>
                                        Kwik Fill Fleet Fueling
                                    </div>

                                    <div onClick={() => { this.router.navigate('/fleet-fueling-plus') }} className={styles.mobile_menu_subsection}>
                                        United Fleet Fueling
                                    </div>

                                    <div onClick={() => { this.router.navigate('/united-universal-fleet') }} className={styles.mobile_menu_subsection}>
                                        United Universal Fleet
                                    </div>

                                    <div onClick={() => { this.router.navigate('/gift-cards-balance') }} className={styles.mobile_menu_subsection}>
                                        Kwik Fill Gift Card
                                    </div>

                                    <div onClick={() => { this.router.navigate('/rewards') }} className={styles.mobile_menu_subsection}>
                                        Kwik Fill Rewards Club
                                    </div>
                                </>
                                }
                            </div>

                            <div className={styles.mobile_menu_section}>
                                <div className={styles.mobile_menu_option} onClick={() => { this.setState({ show_mobile_win_options: !this.state.show_mobile_win_options })}}>
                                    <div style={{ flex: 1 }}>Win!</div>

                                    { !this.state.show_mobile_win_options &&
                                    <svg style={{ width: 14, height: 14 }} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.70312 10.0767V0.474431H6.61364V10.0767H4.70312ZM0.860795 6.22727V4.31676H10.4631V6.22727H0.860795Z" fill="#009146"/>
                                    </svg>
                                    }

                                    { this.state.show_mobile_win_options &&
                                    <svg style={{ width: 14, height: 14 }} width="10" height="3" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 0.933238V2.77273H0V0.933238H10Z" fill="#009146"/>
                                    </svg>
                                    }
                                </div>

                                { this.state.show_mobile_win_options &&
                                <>
                                    <div onClick={() => { this.router.navigate('/fueling-discovery'); }} className={styles.mobile_menu_subsection}>
                                        Enter Giveaway
                                    </div>

                                    <div onClick={() => { this.router.navigate('/account/winners'); }} className={styles.mobile_menu_subsection}>
                                        View Previous Winners
                                    </div>
                                </>
                                }
                            </div>
                            
                            <div className={styles.mobile_menu_section}>
                                <div className={styles.mobile_menu_option} onClick={() => { this.setState({ show_mobile_community_options: !this.state.show_mobile_community_options })}}>
                                    <div style={{ flex: 1 }}>Community</div>

                                    { !this.state.show_mobile_community_options &&
                                    <svg style={{ width: 14, height: 14 }} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.70312 10.0767V0.474431H6.61364V10.0767H4.70312ZM0.860795 6.22727V4.31676H10.4631V6.22727H0.860795Z" fill="#009146"/>
                                    </svg>
                                    }

                                    { this.state.show_mobile_community_options &&
                                    <svg style={{ width: 14, height: 14 }} width="10" height="3" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 0.933238V2.77273H0V0.933238H10Z" fill="#009146"/>
                                    </svg>
                                    }

                                </div>
                                { this.state.show_mobile_community_options &&
                                <>
                                <div onClick={() => { this.router.navigate('/news'); }} className={styles.mobile_menu_subsection}>
                                        News
                                    </div>
                                    <div onClick={() => { this.router.navigate('/fueling-discovery'); }} className={styles.mobile_menu_subsection}>
                                        Fueling Discovery
                                    </div>

                                    <div onClick={() => { this.router.navigate('/operation-give-thanks'); }} className={styles.mobile_menu_subsection}>
                                        Operation Give Thanks
                                    </div>

                                    <div onClick={() => { this.router.navigate('/charities'); }} className={styles.mobile_menu_subsection}>
                                        Charities
                                    </div>
                                </>
                                }
                            </div>

                            <div className={styles.mobile_menu_section}>
                                <div className={styles.mobile_menu_option} onClick={() => { this.setState({ show_mobile_about_options: !this.state.show_mobile_about_options })}}>
                                    <div style={{ flex: 1 }}>About</div>

                                    { !this.state.show_mobile_about_options &&
                                    <svg style={{ width: 14, height: 14 }} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.70312 10.0767V0.474431H6.61364V10.0767H4.70312ZM0.860795 6.22727V4.31676H10.4631V6.22727H0.860795Z" fill="#009146"/>
                                    </svg>
                                    }

                                    { this.state.show_mobile_about_options &&
                                    <svg style={{ width: 14, height: 14 }} width="10" height="3" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 0.933238V2.77273H0V0.933238H10Z" fill="#009146"/>
                                    </svg>
                                    }

                                </div>

                                { this.state.show_mobile_about_options &&
                                <>
                                    <div onClick={() => { this.router.navigate('/driving-america'); }} className={styles.mobile_menu_subsection}>
                                        Driving America
                                    </div>

                                    <div onClick={() => { this.router.navigate('/history-of-kwik-fill'); }} className={styles.mobile_menu_subsection}>
                                        History of Kwik Fill
                                    </div>

                                    <div onClick={() => { this.router.navigate('/history-of-united-refining-company'); }} className={styles.mobile_menu_subsection}>
                                        United Refining Company
                                    </div>
                                </>
                                }
                            </div>

                            <div className={styles.mobile_menu_section}>
                                <div className={styles.mobile_menu_option} onClick={() => { this.setState({ show_mobile_contact_options: !this.state.show_mobile_contact_options })}}>
                                    <div style={{ flex: 1 }}>Contact</div>

                                    { !this.state.show_mobile_contact_options &&
                                    <svg style={{ width: 14, height: 14 }} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.70312 10.0767V0.474431H6.61364V10.0767H4.70312ZM0.860795 6.22727V4.31676H10.4631V6.22727H0.860795Z" fill="#009146"/>
                                    </svg>
                                    }

                                    { this.state.show_mobile_contact_options &&
                                    <svg style={{ width: 14, height: 14 }} width="10" height="3" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 0.933238V2.77273H0V0.933238H10Z" fill="#009146"/>
                                    </svg>
                                    }

                                </div>

                                { this.state.show_mobile_contact_options &&
                                <>
                                    <div onClick={() => { this.router.navigate('/help'); }} className={styles.mobile_menu_subsection}>
                                        Contact Us
                                    </div>

                                    <div onClick={() => { this.router.navigate('/help/faq'); }} className={styles.mobile_menu_subsection}>
                                        FAQ's
                                    </div>

                                    <div onClick={() => { this.router.navigate('/help/forms'); }} className={styles.mobile_menu_subsection}>
                                        Card Services Forms
                                    </div>
                                </>
                                }
                            </div>
                            <div onClick={() => { this.router.navigate('/kwikpay'); }} className={styles.mobile_menu_subsection}>
                                        KwikPAY
                            </div>
                            <div onClick={() => { this.router.navigate('/careers'); }} className={styles.mobile_menu_subsection}>
                                        Careers
                            </div>
                            <div onClick={() => { this.router.navigate('/stores'); }} className={styles.mobile_menu_subsection}>
                                        Find a Store
                            </div>
                        </div>
                    </div>
                </div>
                }

                <div className={styles.container} style={{background: `rgba(0, 145, 70, ${opacity})`}}>
                    <div className={styles.left}>
                        <div onClick={() => { this.home() }} className={styles.left_inner}>
                            <img src={`${process.env.PUBLIC_URL}/assets/navigation/header_logo.svg`}/>
                        </div>
                    </div>

                    <div onClick={() => { this.setState({ show_mobile_menu: true })}} className={styles.mobile_right}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="h-8 w-8"><path fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10zm0 5.25a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75z" clip-rule="evenodd"></path></svg>
                    </div>

                    <div className={styles.right}>
                        <div className={styles.navigation_item_container}>
                            <div className={styles.navigation_item}>Stores</div>

                            <div className={styles.popover}>
                            
                                <div onClick={() => { this.router.navigate('/stores'); }} className={styles.popover_item}>Store Locator</div>
                                <div onClick={() => { this.router.navigate('/truck-stops'); }} className={styles.popover_item}>Truck Stops</div>
                                <div onClick={() => { this.router.navigate('/bradley-street-cafe'); }} className={styles.popover_item}>Bradley Street Cafe</div>
                                <div onClick={() => { this.router.navigate('/specials') }} className={styles.popover_item}>In-Store Specials</div>
                                {/* <div className={styles.popover_item}>Coupons</div> */}
                            </div>
                        </div>

                        <div className={styles.navigation_item_container}>
                            <div className={styles.navigation_item}>Cards</div>

                            <div className={styles.popover}>
                                
                                <div onClick={() => { this.router.navigate('/charge-card') }} className={styles.popover_item}>Charge Card</div>
                                <div onClick={() => { this.router.navigate('/fleet-fueling-card') }} className={styles.popover_item}>Kwik Fill Fleet Fueling</div>
                                <div onClick={() => { this.router.navigate('/fleet-fueling-plus') }} className={styles.popover_item}>United Fleet Fueling</div>
                                <div onClick={() => { this.router.navigate('/united-universal-fleet') }} className={styles.popover_item}>United Universal Fleet</div>
                                <div onClick={() => { this.router.navigate('/gift-cards-balance') }} className={styles.popover_item}>Kwik Fill Gift Card</div>
                                <div onClick={() => { this.router.navigate('/rewards') }} className={styles.popover_item}>Kwik Fill Rewards Club</div>
                            </div>
                        </div>

                        <div className={styles.navigation_item_container}>
                            <div className={styles.navigation_item}>Win!</div>

                            <div className={styles.popover}>
                            
                                <div onClick={() => { this.router.navigate('/win'); }} className={styles.popover_item}>Enter Giveaway</div>
                                <div onClick={() => { this.router.navigate('/account/winners'); }} className={styles.popover_item}>View Previous Winners</div>
                            </div>
                        </div>

                        <div className={styles.navigation_item_container}>
                            <div className={styles.navigation_item}>Community</div>

                            <div className={styles.popover} style={{left: -70}}>
                            <div onClick={() => { this.router.navigate('/news'); }} className={styles.popover_item}>News</div>
                                <div onClick={() => { this.router.navigate('/fueling-discovery'); }} className={styles.popover_item}>Fueling Discovery</div>
                                <div onClick={() => { this.router.navigate('/operation-give-thanks'); }} className={styles.popover_item}>Operation Give Thanks</div>
                                <div onClick={() => { this.router.navigate('/charities'); }} className={styles.popover_item}>Charities</div>
                            </div>
                        </div>

                        <div className={styles.navigation_item_container}>
                            <div className={styles.navigation_item}>About</div>

                            <div className={styles.popover} style={{left: -70}}>

                                <div onClick={() => { this.router.navigate('/driving-america'); }} className={styles.popover_item}>Driving America</div>
                                <div onClick={() => { this.router.navigate('/history-of-kwik-fill'); }} className={styles.popover_item}>History of Kwik Fill</div>
                                <div onClick={() => { this.router.navigate('/history-of-united-refining-company'); }} className={styles.popover_item}>About United Refining</div>
                            </div>
                        </div>

                        <div className={styles.navigation_item_container} style={{marginRight: 12}}>
                            <div onClick={() => { this.router.navigate('/help'); }} className={styles.navigation_item}>Contact</div>

                            <div className={styles.popover} style={{width: 140, right: -20}}>   

                                <div onClick={() => { this.router.navigate('/help'); }} className={styles.popover_item}>Contact Us</div>
                                <div onClick={() => { this.router.navigate('/help/faq'); }} className={styles.popover_item}>FAQs</div>
                                <div onClick={() => { this.router.navigate('/help/forms'); }} className={styles.popover_item}>Card Services Forms</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(NavigationHeader);