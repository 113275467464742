import React from "react";

import NavigationBanner from "@/components/navigation-banner/navigation-banner";
import NavigationFooter from "@/components/navigation-footer/navigation-footer";
import NavigationHeader from "@/components/navigation-header/navigation-header";

import styles from './account.module.css';
import { Route, Routes } from "react-router";
import AccountDetails from "./details";
import AccountGiveaway from "./giveaway";
import AccountEntries from "./past-entries";
import AccountWinners from "./view-winners";
import AccountVIP from "./vip";
import withRouter from "@/components/functional-hook-wrapper";
import AccountLogin from "./login";
import AccountRegister from "./register";
import AccountForgotPassword from "./forgot-password";
import { getProfile } from "@/services/users";
import AccountResetPassword from "@/pages/account/reset-password";

export class Account extends React.Component {
    state = {
        is_logged_in: localStorage.getItem('token')?.length,
        name: '',
    }

    router: any;

    constructor(props: any) {
        super(props);
        this.router = props.router;
        this.getAccountInfo();
    }

    private async getAccountInfo() {
        let response = await getProfile();
        this.setState({name: response?.first_name ?? ''})
    }

    private getWelcomeMessage() {
        let name = localStorage.getItem('first_name');

        if (name && this.state.is_logged_in) {
            return `Welcome back, ${name}`
        } else if (this.state.is_logged_in) {
            return 'Welcome back'
        }

        return 'Welcome to Kwik Fill'
    }

    selectedIndex(index: number) {
        if (index === 5) {
            localStorage.removeItem('token');
            window.location.href = '/'
            return;
        }

        switch (index) {
            case 0:
                window.history.pushState({}, null, '/account');
                this.router.navigate('/account');
                break;
            case 1:
                window.history.pushState({}, null, '/account/giveaway');
                this.router.navigate('/account/giveaway');
                break;
            case 2:
                window.history.pushState({}, null, '/account/entries');
                this.router.navigate('/account/entries');
                break;
            case 3:
                window.history.pushState({}, null, '/account/winners');
                this.router.navigate('/account/winners');
                break;
            case 4:
                window.history.pushState({}, null, '/account/vip');
                this.router.navigate('/account/vip');
                break;
            case 6:
                window.history.pushState({}, null, '/account/login');
                this.router.navigate('/account/login');
                break;
            case 7:
                window.history.pushState({}, null, '/account/register');
                this.router.navigate('/account/register');
                break;
            default:
                break;
        }

        this.setState({})
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.navigation_container}>
                    <NavigationBanner />
                    <NavigationHeader opacity_scroll={false} />
                </div>
                
                <div className={styles.contents_container}>
                    <div className={styles.heading_name}>{this.getWelcomeMessage()}</div>

                    <div className={styles.body_container}>
                        <div style={{ width: 200 }}>
                            <AccountSidebarItem onClick={(index: number) => {
                                this.selectedIndex(index)
                            }} />
                        </div>

                        <div className={styles.body_right}>
                            <Routes>
                                <Route path="/" element={<AccountDetails />}/>
                                <Route path="/login" element={<AccountLogin />}/>
                                <Route path="/register" element={<AccountRegister />}/>
                                <Route path="/forgot-password" element={<AccountForgotPassword />}/>
                                <Route path="/giveaway" element={<AccountGiveaway />}/>
                                <Route path="/entries" element={<AccountEntries />}/>
                                <Route path="/winners" element={<AccountWinners />}/>
                                <Route path="/vip" element={<AccountVIP />}/>
                                <Route path="/reset-password" element={<AccountResetPassword />}/>
                            </Routes>
                        </div>
                    </div>
                </div>

                <NavigationFooter />
            </div>
        );
    }
}

export type AccountSidebarItemProps = {
    onClick?: (index: number) => void;
};

export class AccountSidebarItem extends React.Component<AccountSidebarItemProps> {
    state = {
        is_logged_in: localStorage.getItem('token')?.length
    }

    render() {
        return (
            <div>
                { this.state.is_logged_in ?
                <>
                    <div onClick={() => { this.props.onClick(0); }} className={styles.sidebar_item}>
                        <img onClick={() => { }} className={styles.sidebar_item_icon} src={`${process.env.PUBLIC_URL}/assets/icons/icon_account-details.svg`} alt="Account Details" />
                        Account Details
                    </div>

                    <div onClick={() => { this.props.onClick(1); }} className={styles.sidebar_item}>
                        <img onClick={() => { }} className={styles.sidebar_item_icon} src={`${process.env.PUBLIC_URL}/assets/icons/icon_account-giveaway.svg`} alt="Enter Giveaway" />
                        Enter Giveaway
                    </div>

                    {/* <div onClick={() => { this.props.onClick(2); }} className={styles.sidebar_item}>
                        <img onClick={() => { }} className={styles.sidebar_item_icon} src={`${process.env.PUBLIC_URL}/assets/icons/icon_account-entries.svg`} alt="Past Entries" />
                        Past Entries
                    </div>

                    <div onClick={() => { this.props.onClick(3); }} className={styles.sidebar_item}>
                        <img onClick={() => { }} className={styles.sidebar_item_icon} src={`${process.env.PUBLIC_URL}/assets/icons/icon_account-winners.svg`} alt="View Winners" />
                        View Winners
                    </div> */}

                    <div onClick={() => { this.props.onClick(4); }} className={styles.sidebar_item}>
                        <img onClick={() => { }} className={styles.sidebar_item_icon} src={`${process.env.PUBLIC_URL}/assets/icons/icon_account-vip.svg`} alt="Become a VIP" />
                        Become VIP
                    </div>

                    <div onClick={() => { this.props.onClick(5); }} className={styles.sidebar_item}>
                        <img onClick={() => { }} className={styles.sidebar_item_icon} src={`${process.env.PUBLIC_URL}/assets/icons/icon_account-logout.svg`} alt="Sign out of your account" />
                        Sign Out
                    </div>
                </>
                :
                <>
                    <div onClick={() => { this.props.onClick(6); }} className={styles.sidebar_item}>
                        <img onClick={() => { }} className={styles.sidebar_item_icon} src={`${process.env.PUBLIC_URL}/assets/icons/icon_account-signin.svg`} alt="Account Details" />
                        Sign In
                    </div>

                    <div onClick={() => { this.props.onClick(7); }} className={styles.sidebar_item}>
                        <img onClick={() => { }} className={styles.sidebar_item_icon} src={`${process.env.PUBLIC_URL}/assets/icons/icon_account-signup.svg`} alt="Enter Giveaway" />
                        Sign Up
                    </div>

                    <div onClick={() => { this.props.onClick(1); }} className={styles.sidebar_item}>
                        <img onClick={() => { }} className={styles.sidebar_item_icon} src={`${process.env.PUBLIC_URL}/assets/icons/icon_account-giveaway.svg`} alt="Enter Giveaway" />
                        Enter Giveaway
                    </div>

                    {/* <div onClick={() => { this.props.onClick(3); }} className={styles.sidebar_item}>
                        <img onClick={() => { }} className={styles.sidebar_item_icon} src={`${process.env.PUBLIC_URL}/assets/icons/icon_account-winners.svg`} alt="View Winners" />
                        View Winners
                    </div> */}
                </>
                }
            </div>
        )
    }
}

export default withRouter(Account);