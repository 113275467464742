import AppPromoBanner from "@/components/app-promo-banner";
import React from "react";

import styles from './index.module.css';

export default class AccountEntries extends React.Component {
    state = {
        
    }

    render() {
        return (
            <div className={styles.container}>
                <div>
                    <div className={styles.title}>Past Entries</div>
                    <div className={styles.subtitle}>These are your previous entries for Kwik Fill giveaways.</div>
                </div>

                <div>
                    <div className={[styles.row, styles.section_container].join(' ')}>
                        <div className={styles.left} style={{ fontWeight: 500 }}>
                            Promotion Name
                        </div>

                        <div className={styles.right} style={{ fontWeight: 500 }}>
                            Entry Date
                        </div>
                    </div>

                    {/* ----------- */}

                    <div className={styles.section_container}>
                        <div className={styles.row}>
                            <div className={styles.left}>
                                Promotion Name
                            </div>

                            <div className={styles.right}>
                            12/31/2002
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.left}>
                                Promotion Name
                            </div>

                            <div className={styles.right}>
                                12/31/2002
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.left}>
                                Promotion Name
                            </div>

                            <div className={styles.right}>
                                12/31/2002
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{marginTop: 120, marginBottom: 60}}>
                    <AppPromoBanner />
                </div>
            </div>
        );
    }
}
