export const chargeCard = `
# Kwik Fill Charge Card
Ideal for Personal Everyday Use! </br>
<div id="video">https://www.youtube.com/embed/1O-nDKO3TTs</div> </br> </br>
The Kwik Fill Charge Card is a great way to save money on fuel. It's not a credit card; there is no revolving credit. It's just a smart way to save! Print and mail an application or Apply Below!</br> </br>
<a href="/assets/pdf/2016-05-Kwik-Fill-Charge-Card-Application.pdf" id="button">Printable Application</a><a href="/charge-card.html" id="button">Apply Online</a><a href="/page/fleet-fueling-card" id="button">Business Account</a>
</br></br>
Our Card Services Department can be reached at 888-294-5320 during normal business hours, or you can send us a note using [this communication form](/help).

<hr />
### Features
</br>

<ul></br>
<li>Save 5 cents per gallon on all Gasoline, Diesel, and Kerosene Purchases</li>

<li>Discount will be given right at the pump</li>

<li>Begin your purchase with a Kwik Rewards Club Card* to save an additional 3 cents per gallon (3+5=8)</li></br>

Learn more about the Kwik Rewards Club Card [here](/page/rewards)
</ul>

<hr \>

<ul>
<li>Good for all Merchandise Purchases.  (Lottery excluded)</li>

<li>No Annual Fees</li>

<li>Itemized Monthly Billing</li>

<li>Receive a $5.00 Instant Rebate Coupon Upon Approval of Application</li>

<li>Exclusive coupons and promotions</li>

<li>Accepted at all Kwik Fill locations</li>
</ul>
<hr \>


### KwikPAY
</br>
By enrolling and using KwikPAY, you can:
</br></br>
<ul>
<li>Pay your Kwik Fill Card bill online</li>
<li>Make a quick one-time payment</li>
<li>View status of electronic payments</li>
<li>Receive email receipts for all payments</li>
<li>Manage your payments online</li>
<li>View payment history</li>
<li>Setup recurring payments</li>
<li>Receive email receipts for all payments</li>
</ul>

<br />
<a href="/charge-card.html" id="button">Apply Now</a>

`