export const historyKwikFill = `
# History of Kwik Fill
<img id="image-full" src="/assets/secondary/history-old.jpeg" />
</br></br>
### Kwik Fill is a convenience store chain operated by Warren, PA based United Refining Company.
</br>

In February 1986, John Catsimatidis, CEO of the Red Apple Group Real Estate Company, purchased United Refining Company (URC). Under his direction, URC continues its growth and expansion of our retail gasoline and convenience store business through the acquisition and construction of new locations. Kwik Fill® Gas Stations and Red Apple Food Mart® Convenience Stores are the major commodities of our expansion.
</br></br>
Kwik Fill /Red Apple Food Mart® now consists of nearly 300 company-owned locations and eight independently owned and operated franchise locations in New York, Pennsylvania and Ohio. We pride ourselves on selling American-Made United Refining Company gas, produced from North American crude oil. We have four basic types of stores: gas stations (full and self-service) with no convenience store; gas stations (full and self-service) with small convenience stores; medium sized Red Apple Convenience stores with fuel; and larger Red Apple Convenience stores with fuel (some with Bradley Street Cafes).
</br></br>
Kwik Fill’s locations include four full service diesel truck stops, four mini truck stops, two restaurants, and two truck stop motels. Kwik Fill gas stations are an integral part of the communities we serve, employing over 2300 people throughout N.Y., Pa., and Ohio, supporting local businesses and economies, plus benefiting local charities.
</br></br>
Visit our Store Locator to find a Kwik Fill® or Red Apple near you!
</br></br>
<img id="image-full" src="/assets/secondary/history-new.jpeg" />`