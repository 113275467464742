export const charities = `
# Charities
From local fundraisers to charitable contributions on a more national level, Kwik Fill makes it a priority to not only benefit the customers in their day to day lives, but to also be an asset to the communities where they live and work.
</br></br>
<img id="image" src="/assets/secondary/cff.png" />
</br> 
Each year, United Refining Company (URC) and Kwik Fill partner with a preselect group of charities to support their outstanding work. These agencies find cures, establish new treatments, and provide help for those afflicted with devastating medical conditions. Most collections are in cooperation with two national agencies and support the Muscular Dystrophy and Cystic Fibrosis Foundations.
</br> </br> 

Muscular Dystrophy Association URC and Kwik Fill have a long history with the Muscular Dystrophy Association (MDA), going back over 25 years. MDA holds their fundraising efforts primarily in March around the St Patrick’s Day ‘Shamrock’ concept. Over the last five years, we have collected on average $120,000 per year for MDA.</br></br> 
</br> 
<img id="image" src="/assets/secondary/mda.png" />
</br>
Cystic Fibrosis Foundation Another long-term partnership is with the Cystic Fibrosis Foundation (CF), which we have partnered with for over 20 years. CF holds their fundraising efforts in October to coincide with the Halloween Pumpkin concept. Over the last five years we have collected an average of $53,000 per year.
</br></br>`