export const historyUrc = `
# History of United Refining Company

<img id="image-full" src="/assets/secondary/urc.png" />

</br></br>
### An independent refiner and marketer of petroleum products.
</br>
United fuel cars, trucks and airplanes, as well as, farm and construction equipment. Our products also serve the homes and industries of our communities. Our market includes, Pennsylvania New York and Ohio.
</br></br>
In 1902, United was founded in the cradle of the world petroleum industry. From the start, we have cherished a role of independence in an industry primarily dominated by the petroleum giants. We have continued to remain a free and innovative force in the marketplace for over 100 years, facilitating the benefits of healthy competition to the people in our region.
</br></br>
United owns and operates a modern, fully equipped refinery. While our primary emphasis is placed upon maximizing yields of motor gasoline and distillate fuels, our Refinery also produces a full range of petroleum products. United products include, unleaded gasoline, kerosene, diesel fuel, Number two home heating oil, industrial fuels, liquefied petroleum gas (LPG), propane, and several grades of asphalts.
</br></br>
United continues to grow by expanding and optimizing our Refinery, and by the acquisition and construction of new locations in our retail gasoline and convenience store business. Today, we have over 350 retail outlets in Pennsylvania, New York and Ohio, including service station/convenience stores, truck stops, restaurants, and garages. Our locations are branded Kwik Fill®, Red Apple Food Mart®, Country Fair®, and Keystone®.
</br></br>
United averages approximately 70,000 barrels of oil per day throughout its facilities. The refinery operates 24 hours per day, 7 days a week.
</br></br>
United Refining Company employs over 4000 employees at our facilities. In addition to operating the retail system, United also has a trucking operation in Erie, Pa., terminal operations in Rochester, Tonawanda and West Seneca, N.Y., and Butler and Springdale, Pa. United owns an asphalt terminal in Cordova, Ala., and has an office in Calgary, Alberta, Canada.
</br>
</br>
<img id="image-full" src="/assets/secondary/urc2.png" />
</br>
### Mission Statement
</br>
It is the mission of United Refining Company and Kwik Fill to provide our customers with a stable supply of the highest quality petroleum products at competitive prices, insure corporate profitability and growth, and invest in the future of the company and community, while providing a safe and challenging working environment that encourages the personal development of our employees.
`