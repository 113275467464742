export const operationGiveThanks = `
# Operation Give Thanks
<div id="video"> https://www.youtube.com/embed/2eGNW8dYs2w?si=Hp3HuFk67NGGI0ZO </div>

</br>
### Join with Kwik Fill for Operation Give Thanks to support military families in Kwik Fill Country.
</br>
Operation Give Thanks is a company-and customer-supported program designed to aid active-duty service members, veterans, and their families using various armed forces related charities in Kwik Fill Country.
</br></br>
<a href="https://www.operationgivethanks.com" id="button">Learn More</a>
</br></br>
</br>
</br>
</br>
`