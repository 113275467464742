import React from "react";

import NavigationBanner from "@/components/navigation-banner/navigation-banner";
import NavigationFooter from "@/components/navigation-footer/navigation-footer";
import NavigationHeader from "@/components/navigation-header/navigation-header";

import styles from './help.module.css';
import { Route, Routes } from "react-router";
// import AccountDetails from "./details";
// import AccountGiveaway from "./giveaway";
// import AccountEntries from "./past-entries";
// import AccountWinners from "./view-winners";
// import AccountVIP from "./vip";
import withRouter from "@/components/functional-hook-wrapper";
import HelpContact from "./contact";
import HelpFAQ from "./faq";
import HelpForms from "./forms";
// import AccountLogin from "./login";
// import AccountRegister from "./register";
// import AccountForgotPassword from "./forgot-password";

export class Help extends React.Component {
    state = {
        is_logged_in: false
    }

    router: any;

    constructor(props: any) {
        super(props);
        this.router = props.router;
    }

    selectedIndex(index: number) {
        if (index === 5) {
            window.location.href = '/'
            return;
        }

        switch (index) {
            case 0:
                // window.history.pushState({}, null, '/help');
                this.router.navigate('/help');
                break;
            case 1:
                // window.history.pushState({}, null, '/account/giveaway');
                this.router.navigate('/help/faq');
                break;
            case 2:
                // window.history.pushState({}, null, '/account/entries');
                this.router.navigate('/help/forms');
                break;
            default:
                break;
        }

        this.setState({})
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.navigation_container}>
                    <NavigationBanner />
                    <NavigationHeader opacity_scroll={false} />
                </div>
                
                <div className={styles.contents_container}>
                    <div className={styles.heading_name}>Help Center</div>

                    <div className={styles.body_container}>
                        <div style={{ width: 200 }}>
                            <HelpSidebarItem onClick={(index: number) => {
                                this.selectedIndex(index)
                            }} />
                        </div>

                        <div className={styles.body_right}>
                            <Routes>
                                <Route path="/" element={<HelpContact />}/>
                                <Route path="/faq" element={<HelpFAQ />}/>
                                <Route path="/forms" element={<HelpForms />}/>
                            </Routes>
                        </div>
                    </div>
                </div>

                <NavigationFooter />
            </div>
        );
    }
}

export type HelpSidebarItemProps = {
    onClick?: (index: number) => void;
};

export class HelpSidebarItem extends React.Component<HelpSidebarItemProps> {
    state = {
        is_logged_in: localStorage.getItem('token')?.length,
    }

    render() {
        return (
            <div>
                <div onClick={() => { this.props.onClick(0); }} className={styles.sidebar_item}>
                    <img onClick={() => { }} className={styles.sidebar_item_icon} src={`${process.env.PUBLIC_URL}/assets/icons/icon_account-details.svg`} alt="Account Details" />
                    Contact Us
                </div>

                <div onClick={() => { this.props.onClick(1); }} className={styles.sidebar_item}>
                    <img onClick={() => { }} className={styles.sidebar_item_icon} src={`${process.env.PUBLIC_URL}/assets/icons/icon_account-giveaway.svg`} alt="Enter Giveaway" />
                    FAQs
                </div>

                <div onClick={() => { this.props.onClick(2); }} className={styles.sidebar_item}>
                    <img onClick={() => { }} className={styles.sidebar_item_icon} src={`${process.env.PUBLIC_URL}/assets/icons/icon_account-entries.svg`} alt="Past Entries" />
                    Forms
                </div>
            </div>
        )
    }
}

export default withRouter(Help);