import React from "react";

import AppPromoBanner from "@/components/app-promo-banner";
import withRouter from "@/components/functional-hook-wrapper";
import { loginUser } from "@/services/users";

import styles from './index.module.css';
import BaseButton, { BaseButtonType } from "@/components/base-button/base-button";

export class AccountLogin extends React.Component {
    state = {
        email: '',
        password: '',
        didConfirmAccount: false,
    }

    router: any;

    constructor(props: any) {
        super(props);
        this.router = props.router;
    }

    private async login() {
        localStorage.removeItem('token')
        let response = await loginUser(this.state.email, this.state.password);

        if (response?.token)  {
            window.location.href = '/account'
        } else {
            alert('Incorrect user name or password');
        }
    }

    private forgotPassword() {
        window.history.pushState({}, null, '/account/forgot-password');
        this.router.navigate('/account/forgot-password');
    }

    render() {
        return (
            <div className={styles.container}>
                { this.state.didConfirmAccount ?
                    <div>
                        <div className={styles.title}>Your account has been verified!</div>
                        <div className={styles.subtitle}>You can now log in to your new Kwik Fill Account.</div>
                    </div>
                    :
                    <div>
                        <div className={styles.title}>Sign In</div>
                        <div className={styles.subtitle}>Thank you for signing in. Using the links on this page, you can enter our giveaways see your entries, and view the winners. You can also update your user information and register to become a Kwik Rewards Club VIP.</div>
                    </div>
                }
                

                <div>
                    <div className={styles.form_container}>
                        <div>
                            <div className={styles.input_label}>Email</div>
                            <input className={styles.input} placeholder="Enter email" value={this.state.email} onChange={(event) => {
                                this.setState({ email: event.target.value })
                            }} />
                        </div>
                        
                        <div>
                            <div className={styles.input_label}>Password</div>
                            <input className={styles.input} type="password" placeholder="Enter password" value={this.state.password} onChange={(event) => {
                                this.setState({ password: event.target.value })
                            }} />
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BaseButton type={BaseButtonType.primary} title="Sign In" onClick={() => this.login()} />
                        <div onClick={() => { this.forgotPassword() }} className={styles.link}>Forgot Password?</div>
                        <div style={{flex: 1}}></div>
                    </div>
                </div>

                <div style={{marginTop: 120, marginBottom: 60}}>
                    <AppPromoBanner />
                </div>
            </div>
        );
    }
}

export default withRouter(AccountLogin);