import React from "react";
import styles from './social.module.css';

export default class HomeSocial extends React.Component {
    state = {
        selected_tab_index: 0
    }

    private clickFacebook() {
        window.open("https://www.facebook.com/KwikFill");
    }

    private clickTwitter() {
        window.open("https://twitter.com/kwikfill")
    }

    private clickInstagram() {
        window.open("https://www.instagram.com/kwikfill/")
    }

    private clickYoutube() {
        window.open("https://www.youtube.com/channel/UCSomUa_mrjBh9ilzEQIvcag")
    }

    private getYouTubeLink() {
        switch(this.state.selected_tab_index) {
            case 0:
                return "https://www.youtube.com/embed/6RQ280IV4Dc"
            case 1:
                return "https://www.youtube.com/embed/fHhMcb_3IZQ"
            case 2:
                return "https://www.youtube.com/embed/NuOC9e2C6dw"
            case 3:
                return "https://www.youtube.com/embed/vU-8eVy3FY8"
        }
    }

    render() {
        return (
            <div className={styles.container}>
                <div>
                    <div onClick={() => { this.setState({ selected_tab_index: 1 })}} className={[styles.pill, this.state.selected_tab_index === 1 ? styles.pill_selected : ''].join(' ')}>Kwik Fill USA</div>
                    <div onClick={() => { this.setState({ selected_tab_index: 2 })}} className={[styles.pill, this.state.selected_tab_index === 2 ? styles.pill_selected : ''].join(' ')}>Gift Card</div>
                    <div onClick={() => { this.setState({ selected_tab_index: 3 })}} className={[styles.pill, this.state.selected_tab_index === 3 ? styles.pill_selected : ''].join(' ')}>Fleet Fueling</div>
                    <div onClick={() => { this.setState({ selected_tab_index: 0 })}} className={[styles.pill, this.state.selected_tab_index === 0 ? styles.pill_selected : ''].join(' ')}>Fueling Discovery</div>
                </div>

                <div>
                    <div className={styles.iframe_container}>
                        <iframe className={styles.iframe} src={this.getYouTubeLink()} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>

                <div>
                    <div className={styles.social_title}>@KwikFill</div>

                    <div className={styles.social_container}>
                        <div onClick={() => { this.clickFacebook() }} className={styles.social_square} style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/home/social_facebook-background.png)`}}>
                            <img src={`${process.env.PUBLIC_URL}/assets/home/social_facebook.svg`} className={styles.social_icon} />
                        </div>

                        <div onClick={() => { this.clickYoutube() }} className={styles.social_square} style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/home/social_youtube-background.png)`}}>
                            <img src={`${process.env.PUBLIC_URL}/assets/home/social_youtube.svg`} className={styles.social_icon} />
                        </div>

                        <div onClick={() => { this.clickInstagram() }} className={styles.social_square} style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/home/social_instagram-background.png)`}}>
                            <img src={`${process.env.PUBLIC_URL}/assets/home/social_instagram.svg`} className={styles.social_icon} />
                        </div>

                        <div onClick={() => { this.clickTwitter() }} className={styles.social_square} style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/home/social_twitter-background.png)`}}>
                            <img src={`${process.env.PUBLIC_URL}/assets/home/social_twitter.svg`} className={styles.social_icon} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
