import React from "react";
import withRouter from "../functional-hook-wrapper";
import styles from './navigation-footer.module.css';

export class NavigationFooter extends React.Component {
    state = {
        is_logged_in: localStorage.getItem('token')?.length,
    }

    router: any;

    constructor(props: any) {
        super(props);
        this.router = props.router;
    }

    private onLinkClick(link: string) {
        window.history.pushState({}, null, link);
        this.router.navigate(link);
        window.scrollTo(0, 0);
    }

    private clickFacebook() {
        window.open("https://www.facebook.com/KwikFill");
    }

    private clickTwitter() {
        window.open("https://twitter.com/kwikfill")
    }

    private clickInstagram() {
        window.open("https://www.instagram.com/kwikfill/")
    }

    private clickYoutube() {
        window.open("https://www.youtube.com/channel/UCSomUa_mrjBh9ilzEQIvcag")
    }

    private clickURC() {
        window.open("https://www.urc.com")
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.top}>
                    <div className={styles.top_container}>
                        <div className={styles.top_column}>
                            <img src={`${process.env.PUBLIC_URL}/assets/navigation/header_logo.svg`} alt='KwikFill Logo' style={{height: 25}}/>
                            <div onClick={() => { this.clickFacebook() }} className={styles.navigation_item}>Facebook</div>
                            <div onClick={() => { this.clickYoutube() }} className={styles.navigation_item}>YouTube</div>
                            <div onClick={() => { this.clickInstagram() }} className={styles.navigation_item}>Instagram</div>
                            <div onClick={() => { this.clickTwitter() }} className={styles.navigation_item}>Twitter</div>
                        </div>

                        <div className={styles.top_column}>
                            <div className={styles.navigation_title}>About</div>
                            <div onClick={() => { this.onLinkClick('/history-of-kwik-fill') }} className={styles.navigation_item}>Kwik Fill</div>
                            <div onClick={() => { this.clickURC() }} className={styles.navigation_item}>United Refining Company</div>
                            <div onClick={() => { this.onLinkClick('/driving-america') }} className={styles.navigation_item}>Driving America</div>
                            <div onClick={() => { this.onLinkClick('/careers') }} className={styles.navigation_item}>Join Our Team</div>
                        </div>

                        <div className={styles.top_column}>
                            <div className={styles.navigation_title}>Account</div>
                            { !this.state.is_logged_in &&
                                <>
                                    <div onClick={() => { this.onLinkClick('/account/login') }} className={styles.navigation_item}>Sign In</div>
                                    <div onClick={() => { this.onLinkClick('/account/register') }} className={styles.navigation_item}>Register</div>
                                </>
                            }
                            <div onClick={() => { this.onLinkClick('/account') }} className={styles.navigation_item}>My Account</div>
                            <div onClick={() => { this.onLinkClick('/kwikpay') }} className={styles.navigation_item}>KwikPAY</div>
                        </div>

                        <div className={styles.top_column}>
                            <div className={styles.navigation_title}>Help</div>
                            <div onClick={() => { this.onLinkClick('/help') }} className={styles.navigation_item}>Contact Us</div>
                            <div onClick={() => { this.onLinkClick('/help/faq') }} className={styles.navigation_item}>Frequently Asked Questions</div>
                        </div>
                    </div>
                </div>

                <div className={styles.bottom}>
                    <div className={styles.bottom_border}></div>
                    
                    <div className={styles.bottom_container}>
                        <div className={styles.bottom_left}>© {new Date().getFullYear()} KwikFill, a division of United Refining Company. All rights reserved.</div>
                        <div className={styles.bottom_right}>
                            <div onClick={() => { this.onLinkClick('/privacy-policy') }} className={styles.navigation_item_bottom}> Privacy Policy</div> 
                            <div style={{ marginLeft: 8, marginRight: 8, display: 'inline-block' }}>|</div>
                            <div onClick={() => { this.onLinkClick('/terms-of-use') }} className={styles.navigation_item_bottom}>Terms & Conditions</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(NavigationFooter);