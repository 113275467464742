import React from "react";
import styles from './store.module.css';
import NavigationFooter from "@/components/navigation-footer/navigation-footer";
import NavigationBanner from "@/components/navigation-banner/navigation-banner";
import NavigationHeader from "@/components/navigation-header/navigation-header";

import GoogleMapReact from 'google-map-react';
import Marker from "./Marker";
import { getStoreLocations } from "@/services/users";
import Geocode from 'react-geocode';

export type StateType = {
    address: string,
    stores: any[],
    stores_to_show: any[],
    items: any[],
    show_filters: boolean,
    map_center_latitude: number,
    map_center_longitude: number,
    user_latitude: number,
    user_longitude: number,
}

export default class StoreLocatorPage extends React.Component {
    state: StateType = {
        address: '',
        stores: [],
        stores_to_show: [],
        items: [
            { selected: false, label: '24 Hours' },
            { selected: false, label: 'Full Serve' },
            { selected: false, label: 'ATM' },
            { selected: false, label: 'Gaming Lounge' },
            { selected: false, label: 'Beer' },
            { selected: false, label: 'Kerosene' },
            { selected: false, label: 'Bradley St. Cafe' },
            { selected: false, label: 'Lottery' },
            { selected: false, label: 'Charley Biggs' },
            { selected: false, label: 'Motel' },
            { selected: false, label: 'Convenience' },
            { selected: false, label: 'Pay at the Pump' },
            { selected: false, label: 'DEF' },
            { selected: false, label: 'Propane' },
            { selected: false, label: 'Diesel' },
            { selected: false, label: 'Rooms' },
            { selected: false, label: 'Ethanol Free' },
            { selected: false, label: 'Subway' },
            { selected: false, label: 'Truck Stop'},
        ],
        show_filters: false,
        map_center_latitude: 41.9891,
        map_center_longitude: -79.1131,
        user_latitude: 41.9891,
        user_longitude: -79.1131,
    }
    

    componentDidMount = async () => {
        this.state.stores = await getStoreLocations();
        let stores = Object.assign([], this.state.stores);
        stores.forEach((store) => {
            store.distance = this.haversineFunction(store);
        })
        let sorted = stores
            .sort((a, b) => a.distance < b.distance ? -1 : 1);
        this.setState({stores_to_show: sorted});

        this.getCurrentGeoLocation()
    }

    private showPosition = (position: any) => {
        console.log(position);
        let geoPosition = {latitude: position.coords.latitude, longitude: position.coords.longitude};
        this.setGeoState(geoPosition);
    }

    private setGeoState = (data: any) => {
        this.state.user_latitude = data.latitude;
        this.state.user_longitude = data.longitude;
        this.state.map_center_latitude = data.latitude;
        this.state.map_center_longitude = data.longitude;
        this.setState({});
        this.sortStoresByDistance();
    }

    private getCurrentGeoLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition)
        } else {
            console.log("Geolocation is not supported by this browser");
        }
    }

    private sortStoresByDistance() {
        let stores = Object.assign([], this.state.stores);
        stores.forEach((store) => {
            store.distance = this.haversineFunction(store);
        })
        let sorted = stores
            .sort((a, b) => a.distance < b.distance ? -1 : 1);
        this.setState({stores_to_show: sorted});
    }

    private haversineFunction(data: any) {
        var haversine = require('haversine');
        let start = {
            latitude: this.state.user_latitude,
            longitude: this.state.user_longitude,
        };
        let end = {
            latitude: data.latitude,
            longitude: data.longitude,
        };
        const haversineCoords = (haversine(start, end, {unit: 'mile'}));
        if (data.longitude === null || data.latitude === null) {
            return
        } else {
            data.distance = haversineCoords;

            return data.distance
        }
    }

    private geocodeAddress() {
        // AIzaSyB-5ZKjx-zDARRYnpKBkpzkP4W7eVEFSig
        Geocode.setApiKey("AIzaSyB-5ZKjx-zDARRYnpKBkpzkP4W7eVEFSig");
        Geocode.setLanguage("en");
        Geocode.fromAddress(this.state.address).then(
            (response: any) => {
                const data = response.results[0].geometry.location;
                const position = {
                    coords: {
                        latitude: data.lat,
                        longitude: data.lng,
                    }
                }
                this.showPosition(position);
            },
            (error: any) => {
                console.log(error);
            }
        )
    }

    private getSelectedCount(): number {
        let filter = this.state.items.filter((item) => { return item.selected === true })
        return filter.length;
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.navigation_container}>
                    <NavigationBanner />
                    <NavigationHeader opacity_scroll={false} />
                </div>
                
                <div className={styles.contents_container}>
                    <div className={styles.heading_name}>Find a store</div>

                    <div className={styles.store_container}>
                        <div className={styles.left}>
                            <div className={styles.left_container}>
                                <input className={styles.input}
                                    type="text"
                                    placeholder="Enter an address or zip code"
                                    value={this.state.address}
                                    onChange={(event) => {
                                        this.setState({address: event.target.value});
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.code === 'Enter') {
                                            this.geocodeAddress();
                                        }
                                    }}
                                />
                                <div className={styles.use_current_location_label}>Or use your <span onClick={() => { this.getCurrentGeoLocation() }} className={styles.current_location_label}>current location</span></div>
                                <div className={styles.filter_container}>
                                    <div className={styles.filters_header} onClick={() => {this.setState({show_filters: !this.state.show_filters})}}>
                                        <div className={styles.filters_title}>Filters ({this.getSelectedCount()} applied)</div>
                                        <div className={styles.filters_icon}>{this.state.show_filters ? '-' : '+'}</div>
                                    </div>

                                    { this.state.show_filters &&
                                        <div>
                                            <div className={styles.filter_options}>
                                                { this.state.items.map((item: any, index: number) => {
                                                    return <div key={index} style={{ marginBottom: 8 }}>
                                                        <input type={'checkbox'} checked={item.selected} onChange={(event) => {
                                                            let items = this.state.items
                                                            items[index].selected = !items[index].selected
                                                            this.setState({ items: items })
                                                        }} />
                                                        <span className={styles.filter_label}>{item.label}</span>
                                                    </div>
                                                })}
                                            </div>

                                            <div className={styles.primary_button} style={{ marginBottom: 12 }}>Apply</div>
                                        </div>
                                    }
                                </div>

                                <div className={styles.nearby_stores}>
                                    { this.state.stores_to_show &&
                                        this.state.stores_to_show.map((store) => {
                                            return (
                                                <StoreLocatorResult
                                                    storeInfo={store}
                                                    onPress={() => {
                                                        this.setState({
                                                            map_center_latitude: store.latitude,
                                                            map_center_longitude: store.longitude
                                                        });
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </div>

                            </div>
                        </div>

                        <div className={styles.right}>
                            <div style={{ height: '100vh', width: '100%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "AIzaSyB-5ZKjx-zDARRYnpKBkpzkP4W7eVEFSig" }}
                                    defaultZoom={11}
                                    center={{lat: this.state.map_center_latitude, lng: this.state.map_center_longitude}}
                                    // yesIWantToUseGoogleMapApiInternals
                                    options={{ 
                                        disableDefaultUI: true,
                                        styles: [
                                            {"featureType":"all","elementType":"labels.text","stylers":[{"color":"#878787"}]},
                                            {"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"off"}]},
                                            {"featureType":"landscape","elementType":"all","stylers":[{"color":"#f9f5ed"}]},
                                            {"featureType":"road.highway","elementType":"all","stylers":[{"color":"#f5f5f5"}]},
                                            {"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#c9c9c9"}]},
                                            {"featureType":"water","elementType":"all","stylers":[{"color":"#aee0f4"}]}
                                        ] 
                                    }}
                                >
                                    { this.state.stores &&
                                        this.state.stores.map((store) => {
                                            return (
                                                <Marker
                                                    lat={store.latitude}
                                                    lng={store.longitude}
                                                    name={store.store_number}
                                                    color="#009146"
                                                    // price={store.gas_price}
                                                />
                                            )
                                        })
                                    }

                                    <Marker
                                        lat={this.state.user_latitude}
                                        lng={this.state.user_longitude}
                                        color='#000000'
                                    />
                                </GoogleMapReact>
                                </div>
                        </div>
                    </div>
                </div>

                <NavigationFooter />
            </div>
        );
    }
}

export type StoreLocatorResultProps = {
    storeInfo: any;
    onPress: () => void;
    didSelectDirections?: () => void;
    didSelectPhone?: () => void;
}

export class StoreLocatorResult extends React.Component<StoreLocatorResultProps> {

    private address() {
        let address = '';
        
        if (this.props.storeInfo.address1) {
            address += this.props.storeInfo.address1;
        }

        if (this.props.storeInfo.address2) {
            address += ` ${this.props.storeInfo.address2}`;
        }

        if (this.props.storeInfo.city) {
            address += ` ${this.props.storeInfo.city},`;
        }

        if (this.props.storeInfo.state) {
            address += ` ${this.props.storeInfo.state}`
        }

        if (this.props.storeInfo.zip) {
            address += ` ${this.props.storeInfo.zip}`
        }

        return address
    }

    private formatPhoneNumber(number: string) {
        const cleaned = number.replace(/[^\d]/g, '');

	    const match = cleaned.match(/^(1|44|61|57|55|49|)?(\d{3})(\d{3})(\d{3,})$/);
	    if (match) {
		    const intlCode = match[1] ? `+${match[1]} ` : '';

		    return `${intlCode} (${match[2]}) ${match[3]}-${match[4]}`;
	    }

	    return cleaned
    }

    render() {
        return (
            <div onClick={() => this.props.onPress()}>
                <div className={styles.results_container}>
                    <div className={styles.results_title}>{`Kwik Fill #${this.props.storeInfo.store_number} - ${this.props.storeInfo.city}`}</div>

                    <div>
                        <div className={styles.primary_button} onClick={() => {}}>Directions</div>
                        <div className={styles.secondary_button} onClick={() => {}}>{this.formatPhoneNumber(this.props.storeInfo.phone)}</div>
                    </div>

                    <div className={styles.result_address}>{this.address()}</div>

                    <div className={styles.result_prices}>
                    {(this.props.storeInfo.gas_price !== null || this.props.storeInfo.diesel_price !== null) ? (
                        <>
                        { this.props.storeInfo.gas_price !== null && this.props.storeInfo.gas_price !== "NaN" ?
                            <>
                                Regular: ${Math.floor(this.props.storeInfo.gas_price * 100) / 100}<br />
                            </>
                            : <></>
                        }
                        {this.props.storeInfo.diesel_price !== null  && this.props.storeInfo.diesel_price !== "NaN" && (
                            <>Diesel: ${Math.floor(this.props.storeInfo.diesel_price * 100) / 100}</>
                        )}
                        </>
                    ) : (
                        "Gas prices for this location are not available."
                    )}
                    </div>

                    {/* <div className={styles.result_small_text} style={{marginTop: 12, marginBottom: 12}}>
                        Prices as of ***NEED TO SET DATE AND TIME HERE***
                    </div> */}

                    <div>
                        <ul style={{ marginLeft: 16 }}>
                            { this.props.storeInfo?.features?.map((amenity: any) => {
                                return <li>{amenity}</li>
                            })}
                        </ul>
                    </div>

                    {/* <div className={styles.result_small_text} style={{ marginTop: 12, marginBottom: 8 }}>
                        Subtitle A<br />
                        Subtitle B<br />
                        Subtitle C
                    </div> */}
                    
                </div>
            </div>
        )
    }
}