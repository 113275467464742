import AppPromoBanner from "@/components/app-promo-banner";
import React from "react";
import { submitResetPassword } from '@/services/users';

import styles from './index.module.css';
import withRouter from "@/components/functional-hook-wrapper";

export class AccountResetPassword extends React.Component {
    state = {
        password: '',
        confirmPassword: '',
        error: ''
    }

    router: any;

    constructor(props: any) {
        super(props);

        this.router = props.router;
    }

    private async resetPassword() {
        if (this.state.password !== this.state.confirmPassword) {
            this.setState({ error: 'Passwords do not match.' });
            return;
        }
    
        const query = new URLSearchParams(window.location.search); // <-- Use window.location.search
        const token = query.get('reset_token');
    
        try {
            await submitResetPassword(token, this.state.password);
    
            // Show a confirmation dialog
            window.alert("Your password has successfully been reset");
    
            // Redirect to the login page
            window.location.href = '/account/login'; // Redirect using window.location.href
        } catch (error) {
            this.setState({ error: error?.response?.data?.error?.description });
        }
    }

    render() {
        return (
            <div className={styles.container}>
                <div>
                    <div className={styles.title}>Reset Password</div>
                    <div className={styles.subtitle}>Enter in your new password.</div>
                </div>

                <div>
                    <div className={styles.form_container}>
                        <div>
                            <div className={styles.input_label}>New Password</div>
                            <input type="password" className={styles.input} placeholder="Enter password" value={this.state.password} onChange={(event) => {
                                this.setState({password: event.target.value});
                            }} />
                        </div>

                        <div>
                            <div className={styles.input_label}>Confirm Password</div>
                            <input type="password" className={styles.input} placeholder="Confirm password" value={this.state.confirmPassword} onChange={(event) => {
                                this.setState({confirmPassword: event.target.value});
                            }} />
                        </div>
                    </div>

                    <p style={{ fontSize: 12, color: 'red', marginTop: 0, marginBottom: 12 }}>{this.state.error}</p>

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                        <div className={styles.primary_button} onClick={() => this.resetPassword()}>Reset Password</div>
                        <div style={{flex: 1}}></div>
                    </div>
                </div>

                <div style={{marginTop: 120, marginBottom: 60}}>
                    <AppPromoBanner />
                </div>
            </div>
        );
    }
}

export default withRouter(AccountResetPassword);