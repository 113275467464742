import React from "react";

import NavigationBanner from "@/components/navigation-banner/navigation-banner";
import NavigationFooter from "@/components/navigation-footer/navigation-footer";
import NavigationHeader from "@/components/navigation-header/navigation-header";

import styles from './index.module.css';
import withRouter from "@/components/functional-hook-wrapper";

export class UnderConstruction extends React.Component {
    state = {
        
    }

    render() {
        return (
            <div className={styles.container}>
                {/* <div className={styles.navigation_container}>
                    <NavigationBanner />
                    <NavigationHeader opacity_scroll={false} />
                </div> */}
                
                <div className={styles.contents_container}>
                    <div className={styles.heading_name}>We'll be back!</div>

                    <div className={styles.body_container}>
                        We're currently updating our website. Please check back later.
                    </div>
                </div>

                {/* <NavigationFooter /> */}
            </div>
        );
    }
}

export default withRouter(UnderConstruction);