export const bradleyStreetCafe = `
# Bradley Street Cafe
<div id="video"> https://www.youtube.com/embed/A6tRwB70Cr0 </div>
</br></br>
### Fill Up Inside Too - At Bradley Street Cafe!
</br>
Bradley Street Cafe offers the finest foods - fast! Whether you're headed for work, play or home, stop by Kwik Fill Bradley Street Cafe to satisfy your hunger! Our carry-out convenience means you can feed the whole family or the gang at work. What's for breakfast? Start with a trip to Kwik Fill's Bradley Street Cafe for a breakfast sandwich and a cup of fresh, hot Bradley Street coffee or cappuccino! We offer all of your favorites, including bacon, sausage, and ham muffin or biscuit sandwiches. Stop by for lunch and dinner to fill up with our delicious pizza, hot and cold sandwiches, salads, wraps, and more!
</br></br>
<ul>
<li>7 Warren Road Sugar Grove, PA</li>
<li>12201 E Main Road North East, PA</li>
<li>4320 Old Bloss Road Blossburg, PA</li>
<li>845 Addison Road, Route 417 Painted Post, NY</li>
<li>1 East Washington Street Ellicottville, NY</li>
<li>2930 Main Street Ext. Jamestown, NY</li>
<li>21 Country Route 115 Lindley, NY</li>
<li>27 Albany Street Port Crane, NY</li>
</ul>`