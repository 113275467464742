import React from "react";

import styles from './index.module.css';

export default class AppPromoBanner extends React.Component {
    state = {
        
    }

    private openURL(url: string) {
        window.open(url, '_blank');
    }

    render() {
        return (
            <div className={styles.container}>
                <div>
                    <div className={styles.title}>Entering sweepstakes is easier with the app!</div>
                    <div className={styles.subtitle}>Instantly enter every day just by signing in.</div>

                    <div>
                        <img onClick={() => { this.openURL('https://apps.apple.com/us/app/kwik-fill/id1557470232') }} className={[styles.apple_store, styles.sidebar_item_icon].join(' ')} src={`${process.env.PUBLIC_URL}/assets/app/promo_ios-app-store.png`} alt="Apple App Store - Mobile app download" />
                        <img onClick={() => { this.openURL('https://play.google.com/store/apps/details?id=com.kwikfill.app&hl=en_US&gl=US') }} className={[styles.google_store, styles.sidebar_item_icon].join(' ')} src={`${process.env.PUBLIC_URL}/assets/app/promo_android-app-store.png`} alt="Google App Store - Mobile app download" />
                    </div>
                </div>
            </div>
        );
    }
}
