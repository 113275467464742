export const careers = `
# Careers
<img id="image-full" src="./assets/secondary/forbes.png">
</br> </br>
<a href="https://jobs.kwikfill.com/" id="button">Apply Online</a>
</br></br>
### Make It A Career - Endless Possibilities
At Kwik Fill, we like to promote from within our own ranks whenever possible. Therefore, once you are on our team, there are opportunities to advance to uppermanagement positions such as a District Manager, overseeing 10 -13 locations or as Regional Manager overseeing 5-6 Districts, as well as advancement into the Corporate Offices located in Warren, PA.
</br></br>
<hr />
### We Offer:

<ul>
<li>Job skills that last a lifetime</li>

<li>Competitive wages</li>

<li>Team atmosphere</li>
<li>Flexible scheduling</li>
<li>Advancement opportunities with a growing company</li>
</ul>
</br>
</ul>

<hr \>
### Store Manager
The Store Manager has the day-to-day responsibilities for operating the store within the specific guidelines described by the operating policy.
</br>
<div id="video">https://www.youtube.com/embed/6u0iFG6H-Os</div> 
</br>
<hr \>
### Assistant Manager
The Assistant Store Manager, in the absence of the store manager, has the day-to-day responsibility of operating the store within the specific guidelines described by the operating policy.
</br>

<hr \>
### Deli Manager
The Food Service Department Manager shares responsibility for the departments operation with the store management.  The Food Service Department Manager will be responsible for directing personnel and performing all the duties required to satisfy the customer.
</br>

<hr \>
### Food Service Employee
The Food Service Employee shares responsibility for the departments operation with the store to ensure safe food handling.
</br>

<hr \>
### Shift Leader
The Shift leader position is to develop them to become assistant manager’s and assist the location manager and assistant manager in the operations of the store, as well as help in the developing of new cashiers /attendants. The shift leader will be able to assist and step in to the Assistant manager role in the absence of the manager or Assistant manager with the day-to-day responsibilities of operating the store within the specific guidelines described by the operating policy.
</br>

<hr \>
### Cashier / Attendant
The Cashier/Attendant, in the absence of the Store Manager and the Store Assistant Manager, is responsible for operating the store within the specific guidelines described by the operating policy.
</br>
<div id="video">https://www.youtube.com/embed/N6-x9PWWPYY</div>
</br>
<hr \>
### Fuel Cashier
The Truck Stop Fuel Cashier is responsible for accurately collecting and processing payment from diesel customers.
</br>
<hr \>
</br>


<a href="https://jobs.kwikfill.com/" id="button">Apply Online</a>
</br>
</br>
</br>

`