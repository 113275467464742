import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import './App.css';

// Components
import Account from './pages/account/account';
import UnderConstruction from './pages/under-construction/index';
import Help from './pages/help/help';
import Home from './pages/home/home';
import SecondaryPage from './pages/secondary/secondary';
import StoreLocatorPage from './pages/store/store';
import TertiaryPage from './pages/tertiary/tertiary';
import InStoreOffers from './pages/store/in-store-offers/index';
// import ParksPage from './pages/parks/parks';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/fleet-fueling" render={() => {window.location.href="fleet-fueling.html"}} />
                <Route exact path="/" element={<Home />}/>
                {/* <Route exact path="/" element={<UnderConstruction />}/> */}
                {/* <Route exact path="/account/reset-password" element={<AccountResetPassword />}/> */}
                {/* <Route path="/win" element={<Navigate to="/fueling-discovery" />} /> */}
                <Route exact path="/account/*" element={<Account />}/>
                <Route exact path="/help/*" element={<Help />}/>
                <Route exact path="/stores" element={<StoreLocatorPage />}/>
                <Route exact path="/specials" element={<InStoreOffers />}/>
                <Route exact path="/frame/*" element={<TertiaryPage />}/>
                {/* <Route exact path="/parks/*" element={<ParksPage />}/> */}
                <Route exact path="/*" element={<SecondaryPage />}/>
            </Routes>
        </BrowserRouter>
    );
}
export default App;
